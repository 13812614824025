import { useEffect, useState } from 'react';
import AnthropometryService from '../../../../../services/examinations/AnthropometryService';
import EmptyState from '../EmptyState';

const AnthropometryView = ({ admissionId, handleEditClick }) => {
  const [anthropometry, setAnthropometry] = useState({});

  useEffect(() => {
    fetchAnthropometry();
  }, []);

  const fetchAnthropometry = async () => {
    try {
      const res = await AnthropometryService.getDetail(admissionId);
      setAnthropometry(res);
    } catch (error) {
      console.error(error);
    }
  };

  if (!anthropometry.id) {
    return (
      <EmptyState
        handleEditClick={handleEditClick}
        entityName="Anthropometry"
      />
    );
  }

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  // const renderTitle = (label) => {
  //   return (
  //     <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 pl-3 pt-5">
  //       <label className="mb-2.5 block text-xl bg-white p-3">{label}</label>
  //     </div>
  //   );
  // };

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Underweight', anthropometry.underweight)}
              {renderField('Wasted', anthropometry.wasted)}
              {renderField('Stunted', anthropometry.stunted)}
              {renderField('BSA', anthropometry.bsa)}
              {renderField('SMR Stage', anthropometry.smrStage)}
              {renderField('OFC in cm (Upto 5 years)', anthropometry.ofc)}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Anthropometry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnthropometryView;
