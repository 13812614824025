import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../data/constants";
import {
  SOCIAL_HISTORY_DETAIL,
  SOCIAL_HISTORY_UPDATE,
} from "../data/constants/api";
import { loadState } from "../utils/localStorage";
import Api from "./Api";

class SocialHistoryService {
  async getDetail(patientId) {
    const res = await Api.get(
      template(SOCIAL_HISTORY_DETAIL)({ patientId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(patientId, payload) {
    const res = await Api.post(
      template(SOCIAL_HISTORY_DETAIL)({ patientId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async update(patientId, socialHistoryId, payload) {
    const res = await Api.patch(
      template(SOCIAL_HISTORY_UPDATE)({ patientId, socialHistoryId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new SocialHistoryService();
