const fields = [
  {
    name: "Consanguinity",
    label: "consanguinity",
    type: "select",
    options: ["Yes", "No"]
  },
  {
    name: "Any significant medical history",
    label: "medicalHistory",
    type: "select",
    options: ["Yes", "No"],
  },
  {
    name: "Medical History",
    label: "historyValue",
    type: "text",
    hide: true,
  },
  {
    name: "Similar illness in family member",
    label: "similarIllnessInFamilyMember",
    type: "select",
    options: ["Yes", "No"],
  },
  {
    name: "What illness?",
    label: "whatIllness",
    type: "text",
    hide: true,
  },
  {
    name: "Type of Family",
    label: "typeOfFamily",
    type: "select",
    options: ["Nuclear", "Joint"],
  },
  {
    name: "History of communicable disease contact",
    label: "historyOfCommunicableDiseaseContact",
    type: "text",
  },
  {
    name: "Father Occupation",
    label: "fatherOccupation",
    type: "text",
  },
  {
    name: "Mother Occupation",
    label: "motherOccupation",
    type: "text",
  },
];

export default fields;
