const fields = [
  {
    name: 'BCG',
    label: 'bcg',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'Pentavac',
    label: 'pentavacOPV',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'Pentavac doses',
    label: 'pentavacDoses',
    type: 'select',
    options: ['Dose 1', 'Dose 2', 'Dose 3'],
    hide: false,
  },
  {
    name: 'OPV',
    label: 'opv',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'OPV doses',
    label: 'opvDoses',
    type: 'select',
    options: ['Dose 1', 'Dose 2', 'Dose 3'],
    hide: false,
  },
  {
    name: 'Rota',
    label: 'rota',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'Rota Doses',
    label: 'rotaDoses',
    type: 'select',
    options: ['Dose 1', 'Dose 2'],
    hide: false,
  },
  {
    name: 'PCV 10',
    label: 'pcv10',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'PCV 10 Doses',
    label: 'pcv10Doses',
    type: 'select',
    options: ['Dose 1', 'Dose 2'],
    hide: false,
  },
  {
    name: 'fIPV',
    label: 'fipv',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'fIPV Doses',
    label: 'fipvDoses',
    type: 'select',
    options: ['Dose 1', 'Dose 2'],
    hide: false,
  },
  {
    name: 'MR',
    label: 'mr',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'MR Doses',
    label: 'mrDoses',
    type: 'select',
    options: ['Dose 1', 'Dose 2'],
    hide: false,
  },
  {
    name: 'JE',
    label: 'je',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'TCV',
    label: 'tcv',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'Other',
    label: 'others',
    type: 'text',
  },
];

export default fields;
