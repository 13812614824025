import { DocumentIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DischargeService from '../../../../../services/admission/DischargeService';
import { changeToNepaliDate } from '../../../../../utils';
import EmptyState from '../EmptyState';

const DischargeView = ({ admission, handleEditClick }) => {
  const [discharge, setDischarge] = useState({});

  useEffect(() => {
    fetchDischarge();
  }, []);

  const fetchDischarge = async () => {
    try {
      const res = await DischargeService.getDetail(admission.id);
      setDischarge(res);
    } catch (error) {
      console.error(error);
    }
  };

  if (!discharge.id) {
    return (
      <EmptyState handleEditClick={handleEditClick} entityName="Discharge" />
    );
  }

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  const renderRichField = (label, value) => (
    <div className="flex flex-col flex-grow col-span-full">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  );

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="pt-3 pr-3 flex justify-end">
              <Link
                to={`/discharge/patient/${admission.patientId}/admission/${admission.id}/printpdf`}
                target="_blank"
              >
                <button
                  type="button"
                  className="text-warning hover:text-warning transition mr-3 text-right"
                >
                  <span className="inline">pdf</span>{' '}
                  <DocumentIcon className="h-4 w-4 inline" />
                </button>
              </Link>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Outcome', discharge.outcome)}
              {discharge.outcome === 'Expired' &&
                renderField('Outcome Timing', discharge.outcomeTiming)}
              {renderField(
                'Referred to Other Hospitals',
                discharge.referredToOtherHospitals,
              )}
              {renderField('Follow up', discharge.followUp)}
              {renderField(
                'Discharge Date',
                `${format(
                  new Date(discharge.dateOfDischarge),
                  'yyyy-MM-dd',
                )} (${changeToNepaliDate(
                  format(new Date(discharge.dateOfDischarge), 'yyyy-MM-dd'),
                )})`,
              )}
              {renderField('Discharge Condition', discharge.dischargeCondition)}
              {renderRichField(
                'Course During Hospital Stay',
                discharge.courseDuringHospitalStay,
              )}
              {renderRichField(
                'Advice on Discharge',
                discharge.adviceOnDischarge,
              )}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Discharge
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DischargeView;
