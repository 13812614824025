import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Detail from '../../../components/Dashboard/Admission/Detail/Index';
import Breadcrumb from '../../../components/UI/Breadcrumb/Index';
import { showError } from '../../../data/messages';
import useServerError from '../../../hooks/useServerError';
import AdmissionService from '../../../services/AdmissionService';

const AdmissionDetail = () => {
  const { patientId } = useParams();
  const { admissionId } = useParams();
  const [admission, setAdmission] = useState({});
  const { updateServerError } = useServerError();

  useEffect(() => {
    fetchAdmission();
  }, [admissionId, patientId]);

  const fetchAdmission = async () => {
    try {
      const res = await AdmissionService.getDetail(patientId, admissionId);
      setAdmission(res);
    } catch (error) {
      updateServerError(error, showError);
    }
  };

  return (
    <>
      <Breadcrumb pageName="Admission Detail" />
      <div className="flex flex-col gap-10">
        {admission.id && <Detail admission={admission} />}
      </div>
    </>
  );
};

export default AdmissionDetail;
