const fields = [
  {
    label: 'weightValue',
    name: 'Weight',
    type: 'text',
    required: true,
  },
  {
    name: 'Apgar score at 1 and 5 min (if available)',
    label: 'apgarScore',
    type: 'text',
  },
  {
    name: 'Resuscitation',
    label: 'resuscitation',
    type: 'select',
    options: ['No', 'Bag and Mask', 'BMV and CPR'],
    required: true,
  },
];

let validationRules = {
  weightValue: { required: true },
  resuscitation: { required: true },
};

export default fields;
export { validationRules };
