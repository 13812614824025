export const DETAIL_ME = "/api/auth";
export const LOGIN = "/api/login";
export const USER_LIST = "/api/users";
export const USER_CREATE = "/api/register";
export const CHANGE_PASSWORD = "/api/change-password/${userId}";
export const CHANGE_PROFILE_IMAGE = "/api/users/${userId}/upload-image";

export const LIST_PATIENTS = "/api/patients";
export const PATIENT_DETAIL = "/api/patients/${patientId}";

export const BIRTH_HISTORY_DETAIL = "/api/patients/${patientId}/birth_history";
export const BIRTH_HISTORY_CREATE =
  "/api/patients/${patientId}/birth_history/${birthHistoryId}";

export const BIRTH_DETAILS_DETAIL = "/api/patients/${patientId}/birth_details";
export const BIRTH_DETAILS_UPDATE =
  "/api/patients/${patientId}/birth_details/${birthDetailsId}";

export const NUTRITIONAL_DETAIL =
  "/api/patients/${patientId}/nutritional_history";
export const NUTRITIONAL_HISTORY_UPDATE =
  "/api/patients/${patientId}/nutritional_history/${nutritionalHistoryId}";

export const FAMILY_HISTORY_DETAIL =
  "/api/patients/${patientId}/family_history";
export const FAMILY_HISTORY_UPDATE =
  "/api/patients/${patientId}/family_history/${familyHistoryId}";

export const SOCIAL_HISTORY_DETAIL =
  "/api/patients/${patientId}/social_history";
export const SOCIAL_HISTORY_UPDATE =
  "/api/patients/${patientId}/social_history/${socialHistoryId}";

export const IMMUNE_HISTORY_DETAIL =
  "/api/patients/${patientId}/immune_history";
export const IMMUNE_HISTORY_UPDATE =
  "/api/patients/${patientId}/immune_history/${immuneHistoryId}";

export const LIST_ADMISSION = "/api/patients/${patientId}/admissions";
export const LIST_ALL_ADMISSION = "/api/admission";
export const ADMISSION_CREATE = "/api/patients/${patientId}/admission";
export const ADMISSION_DETAIL =
  "/api/admission/${patientId}/general_admission/${admissionId}";
export const ADMISSION_DELETE = "/api/admission/${id}";
export const DOWNLOAD_PDF =
  "/api/admission/${patientId}/general_admission/${admissionId}/pdf";

export const VITALS_DETAIL = "/api/admission/${admissionId}/exam_vital";
export const VITALS_UPDATE =
  "/api/admission/${admissionId}/exam_vital/${examVitalId}";

export const HEAD_TO_TOE_DETAIL =
  "/api/admission/${admissionId}/exam_head_to_toe";
export const HEAD_TO_TOE_UPDATE =
  "/api/admission/${admissionId}/exam_head_to_toe/${examHeadToToeId}";

export const ANTHROPOMETRY_DETAIL =
  "/api/admission/${admissionId}/exam_anthropometry";
export const ANTHROPOMETRY_UPDATE =
  "/api/admission/${admissionId}/exam_anthropometry/${examAnthropometryId}";

export const SYSTEMIC_DETAIL =
  "/api/admission/${admissionId}/exam_systemic";
export const SYSTEMIC_UPDATE =
  "/api/admission/${admissionId}/exam_systemic/${examSystemicId}";

export const DEVELOPMENTAL_DETAIL =
  "/api/admission/${admissionId}/exam_developmental";
export const DEVELOPMENTAL_UPDATE =
  "/api/admission/${admissionId}/exam_developmental/${examDevelopmentalId}";


export const CARNIAL_NERVE_DETAIL =
  "/api/admission/${admissionId}/exam_cranial_nerve";
export const CARNIAL_NERVE_UPDATE =
  "/api/admission/${admissionId}/exam_cranial_nerve/${examCranialNerveId}";

export const MOTOR_DETAIL = "/api/admission/${admissionId}/exam_motor";
export const MOTOR_UPDATE =
  "/api/admission/${admissionId}/exam_motor/${examMotorId}";

export const DISCHARGE_DETAIL = "/api/admission/${admissionId}/discharge";
export const DISCHARGE_UPDATE =
  "/api/admission/${admissionId}/discharge/${dischargeId}";

export const CREATE_INVESTIGATION =
  "/api/admission/${admissionId}/investigation";
export const FETCH_INVESTIGATION =
  "/api/admission/${admissionId}/investigations";
export const FETCH_INVESTIGATION_IMAGES =
  "/api/admission/${admissionId}/investigation/${investigationType}/images";
export const DELETE_INVESTIGATION_IMAGE = "/api/images/${imageId}/delete";
export const DELETE_INVESTIGATION = "/api/investigation/${id}";
