import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import Dropdown from "./Dropdown";

const Multiselect = ({ items, name, defaultFields, handleChange }) => {
  const [dropdown, setDropdown] = useState(false);
  const [selectedItems, setSelected] = useState([]);

  useEffect(() => {
    // Initialize selectedItems from defaultFields
    setSelected(Array.from(defaultFields) || []);
  }, [defaultFields]);

  const toogleDropdown = () => {
    setDropdown(!dropdown);
  };

  const addTag = (item) => {
    setSelected((prevSelectedItems) => {
      const newSelectedItems = prevSelectedItems.concat(item);
      handleChange(name, newSelectedItems);
      return newSelectedItems;
    });
    setDropdown(false);
  };

  const removeTag = (item) => {
    setSelected((prevSelectedItems) => {
      const filtered = prevSelectedItems.filter((e) => e !== item);
      handleChange(name, filtered);
      return filtered;
    });
  };

  return (
    <div className="autcomplete-wrapper">
      <div className="autcomplete">
        <div className="w-full flex flex-col items-center mx-auto">
          <div className="w-full">
            <div className="flex flex-col items-center relative">
              <div className="w-full ">
                <div className="my-2 p-1 flex border border-gray-200 bg-white rounded ">
                  <div className="flex flex-auto flex-wrap">
                    {selectedItems.map((tag, index) => {
                      return (
                        <div
                          key={index}
                          className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 "
                        >
                          <div className="text-xs font-normal leading-none max-w-full flex-initial">
                            {tag}
                          </div>
                          <div className="flex flex-auto flex-row-reverse">
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                removeTag(tag);
                              }}
                            >
                              <XMarkIcon className="w-3 h-3 ml-1" />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="flex-1">
                      <input
                        placeholder=""
                        className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                      />
                    </div>
                  </div>
                  <div
                    className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                    onClick={(e) => {
                      e.preventDefault();
                      toogleDropdown();
                    }}
                  >
                    <button className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-up w-4 h-4"
                      >
                        <polyline points="18 15 12 9 6 15"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {dropdown ? (
              <Dropdown list={items} addItem={addTag}></Dropdown>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Multiselect;
