import { useEffect, useState } from 'react';
import fields from '../../../../../data/patients/socialHistory/socialHistoryFields';
import initialFields from '../../../../../data/patients/socialHistory/socialHistoryInitialValues';
import SocialHistoryService from '../../../../../services/SocialHistoryService';
import { validateForm } from '../../../../../validation/formValidation';
import PatientForm from '../../Form/Index';

const SocailHistoryCreate = ({
  patientId,
  handleCancel,
  handleSocialHistorySave,
}) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [socialHistoryFields, setSocialHistoryFields] = useState(fields);

  useEffect(() => {
    fetchBirthHistory();
  }, []);

  const fetchBirthHistory = async () => {
    try {
      const res = await SocialHistoryService.getDetail(patientId);
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm(formData, socialHistoryFields);

    if (Object.keys(errors).length === 0) {
      handleSocialHistorySave(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5">
            <PatientForm
              formTitle="Social History"
              formFields={socialHistoryFields}
              formErrors={formErrors}
              formData={formData}
              handleInputChange={handleInputChange}
              cancelButton={true}
              handleCancelButtonClick={handleCancel}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SocailHistoryCreate;
