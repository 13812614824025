import { useEffect, useState } from "react";
import fields, {
  validationRules,
} from "../../../../../data/admissions/developmental/fields";
import initialFields from "../../../../../data/admissions/developmental/initialValues";
import DevelopmentalService from "../../../../../services/examinations/DevelopmentalService";
import { handleDevelopmentalFieldChange } from "../../../../../utils/handleConditionalFields";
import { validateForm } from "../../../../../validation/formValidation";
import PatientForm from "../../../Patient/Form/Index";

const Developmental = ({ admissionId, handleCreate, handleCancel }) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [developmentalFields, setDevelopmentalFields] = useState(fields);

  useEffect(() => {
    fetchDevelopmental();
  }, []);

  const fetchDevelopmental = async () => {
    try {
      const res = await DevelopmentalService.getDetail(admissionId);
      if (res.assessment.toLowerCase() === "abnormal") {
        setDevelopmentalFields((prevFields) =>
          prevFields.map((field) =>
            field.label === "assessmentAbnormal" ? { ...field, hide: false } : field
          )
        );
      }
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleDevelopmentalFieldChange(name, value, setDevelopmentalFields);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm(formData, developmentalFields, validationRules);

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      handleCreate(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5 bg-white">
      <PatientForm
        formTitle="Developmental"
        formFields={developmentalFields}
        formErrors={formErrors}
        formData={formData}
        handleInputChange={handleInputChange}
        cancelButton={true}
        handleCancelButtonClick={handleCancel}
      />
    </form>
  );
};

export default Developmental;
