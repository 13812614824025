import { useEffect, useState } from "react";
import fields from "../../../../../data/patients/immuneHistory/immuneHistoryFields";
import initialFields from "../../../../../data/patients/immuneHistory/immuneHistoryInitialValues";
import ImmunizationService from "../../../../../services/ImmunizationService";
import { handleImmuneHistoryFieldChange } from "../../../../../utils/handleConditionalFields";
import { validateForm } from "../../../../../validation/formValidation";
import PatientForm from "../../Form/Index";

const ImmunizationHistoryCreate = ({
  patientId,
  handleCancel,
  handleImmunisationHistorySave,
}) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [immuneHistoryFields, setImmuneHistoryFields] = useState(fields);

  useEffect(() => {
    fetchImmuneHistory();
  }, []);

  const fetchImmuneHistory = async () => {
    try {
      const res = await ImmunizationService.getDetail(patientId);
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
      const vaccinesToCheck = ["pentavacOPV", "rota", "pcv10", "fipv", "mr"];

      for (const vaccine of vaccinesToCheck) {
        if (res[vaccine].toLowerCase() === "No") {
          setImmuneHistoryFields((prevFields) =>
            prevFields.map((field) =>
              field.label === `${vaccine}Doses`
                ? { ...field, hide: true }
                : field
            )
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, items = []) => {
    if (e === "others") {
      setFormData((prevFormData) => ({ ...prevFormData, others: items }));
    } else {
      const { name, value } = e.target;
      handleImmuneHistoryFieldChange(name, value, setImmuneHistoryFields);

      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm(formData, immuneHistoryFields);

    if (Object.keys(errors).length === 0) {
      handleImmunisationHistorySave(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5">
            <PatientForm
              formTitle="Immunization History"
              formFields={immuneHistoryFields}
              formErrors={formErrors}
              formData={formData}
              handleInputChange={handleInputChange}
              cancelButton={true}
              handleCancelButtonClick={handleCancel}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ImmunizationHistoryCreate;
