import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../data/constants";
import {
  FAMILY_HISTORY_DETAIL,
  FAMILY_HISTORY_UPDATE,
} from "../data/constants/api";
import { loadState } from "../utils/localStorage";
import Api from "./Api";

class FamilyHistoryService {
  async getDetail(patientId) {
    const res = await Api.get(
      template(FAMILY_HISTORY_DETAIL)({ patientId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(patientId, payload) {
    const res = await Api.post(
      template(FAMILY_HISTORY_DETAIL)({ patientId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async update(patientId, familyHistoryId, payload) {
    const res = await Api.patch(
      template(FAMILY_HISTORY_UPDATE)({ patientId, familyHistoryId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new FamilyHistoryService();
