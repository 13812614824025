import GeneralAdmission from "../Sections/GeneralAdmission/Index";

const Index = ({
  patientId,
  handleAdmissionCreate,
  initialFields,
  admissionFields,
  validationRules,
}) => {
  return (
    <GeneralAdmission
      patientId={patientId}
      createAdmission={handleAdmissionCreate}
      initialFields={initialFields}
      fields={admissionFields}
      validationRules={validationRules}
    />
  );
};

export default Index;
