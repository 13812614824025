const fields = [
  {
    name: 'ICD Code',
    label: 'treatmentReceived',
    type: 'text',
    required: false,
  },
];

const validationRules = {
  treatmentReceived: {},
};

export { validationRules };
export default fields;
