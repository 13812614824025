import { format } from 'date-fns';
import { CHILDCARE_USER_TOKEN } from '../data/constants/index';
import { loadState } from './localStorage';
const { adToBs } = require('@sbmdkl/nepali-date-converter');

const isLoggedIn = () => {
  return loadState(CHILDCARE_USER_TOKEN) !== undefined;
};

const changeToNepaliDate = (date) => {
  return adToBs(format(date, 'yyyy-MM-dd'));
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const strongPasswordCheck = (password) => {
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  return strongPasswordRegex.test(password);
};

function capitalizeFirstLetter(string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
  capitalizeFirstLetter,
  changeToNepaliDate,
  getCurrentDate,
  isLoggedIn,
  strongPasswordCheck,
};
