const fields = [
  {
    name: 'Father Education',
    label: 'parentalEducation',
    type: 'select',
    options: ['Primary', 'Secondary', 'High School and Above', 'Uneducated'],
  },
  {
    name: 'Mother Education',
    label: 'motherEducation',
    type: 'select',
    options: ['Primary', 'Secondary', 'High School and Above', 'Uneducated'],
  },
  {
    name: 'Housing condition',
    label: 'housingCondition',
    type: 'select',
    options: ['Pakka', 'Kachha'],
  },
  {
    name: 'Pets at Home',
    label: 'petsAtHome',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    name: 'Water Source',
    label: 'waterSource',
    type: 'select',
    options: ['tap', 'surface', 'ground', 'boiled', 'filtered'],
  },
];

export default fields;
