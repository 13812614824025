import { format } from "date-fns";
import { useEffect, useState } from "react";
import fields, {
  validationRules,
} from "../../../../../data/admissions/discharge/fields";
import initialFields from "../../../../../data/admissions/discharge/initialValues";
import DischargeService from "../../../../../services/admission/DischargeService";
import { handleDischargeFieldChange } from "../../../../../utils/handleConditionalFields";
import { validateForm } from "../../../../../validation/formValidation";
import PatientForm from "../../../Patient/Form/Index";

const Discharge = ({ admission, handleVitalsCreate, handleCancel }) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [dischargeFields, setDischargeFields] = useState(fields);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleDischargeFieldChange(name, value, setDischargeFields);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  useEffect(() => {
    fetchDischarge();
  }, []);

  const fetchDischarge = async () => {
    try {
      const res = await DischargeService.getDetail(admission.id);
      res.dateOfDischarge = format(new Date(res.dateOfDischarge), "yyyy-MM-dd");
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm(formData, dischargeFields, validationRules);

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      handleVitalsCreate(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5 bg-white">
      <PatientForm
        formTitle="Discharge"
        formFields={dischargeFields}
        formErrors={formErrors}
        formData={formData}
        handleInputChange={handleInputChange}
        cancelButton={true}
        handleCancelButtonClick={handleCancel}
      />
    </form>
  );
};

export default Discharge;
