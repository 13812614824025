import React from "react";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function PatientDetailTabs({ updatedTabs }) {
  return (
    <>
      <div className="sm:hidden bg-white">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-meta-3 focus:border-meta-3 focus:border-meta-3"
          defaultValue={updatedTabs.find((tab) => tab.current).name}
        >
          {updatedTabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block bg-white">
        <nav
          className="isolate flex divide-x divide-meta-3 rounded-lg shadow bg-white"
          aria-label="Tabs"
        >
          {updatedTabs.map((tab, tabIdx) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current
                  ? "text-meta-3 bg-transparent dark:text-gray"
                  : "text-meta-3",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === updatedTabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-meta-2 dark:bg-transparent py-4 px-4 text-center text-sm font-medium hover:bg-meta-3 hover:text-gray focus:z-10"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? "bg-meta-2" : "bg-meta-3",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </Link>
          ))}
        </nav>
      </div>
    </>
  );
}

export default PatientDetailTabs;
