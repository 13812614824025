import { useEffect, useState } from "react";
import fields, {
  validationRules,
} from "../../../../../data/admissions/anthropometry/fields";
import initialFields from "../../../../../data/admissions/anthropometry/initialValues";
import AnthropometryService from "../../../../../services/examinations/AnthropometryService";
// import { handleAnthropometryFieldChange } from "../../../../../utils/handleConditionalFields";
import { validateForm } from "../../../../../validation/formValidation";
import PatientForm from "../../../Patient/Form/Index";

const Anthropometry = ({ admissionId, handleCreate, handleCancel }) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [anthropometryFields, setAnthropometryFields] = useState(fields);

  useEffect(() => {
    fetchHeadToToe();
  }, []);

  const fetchHeadToToe = async () => {
    try {
      const res = await AnthropometryService.getDetail(admissionId);
      // TODO: Remove this later
      // if (res.cardiovascularSystem.toLowerCase() === "murmur") {
      //   setAnthropometryFields((prevFields) =>
      //     prevFields.map((field) =>
      //       field.label === "murmurYes" ? { ...field, hide: false } : field
      //     )
      //   );
      // }
      // if (res.respiratoryDistress.toLowerCase() === "yes") {
      //   setAnthropometryFields((prevFields) =>
      //     prevFields.map((field) =>
      //       field.label === "respiratoryDistressYes"
      //         ? { ...field, hide: false }
      //         : field
      //     )
      //   );
      // }
      // if (res.airEntry.toLowerCase() === "decreased") {
      //   setAnthropometryFields((prevFields) =>
      //     prevFields.map((field) =>
      //       field.label === "decreasedAirEntry"
      //         ? { ...field, hide: false }
      //         : field
      //     )
      //   );
      // }
      // if (res.kidney.toLowerCase() === "yes") {
      //   setAnthropometryFields((prevFields) =>
      //     prevFields.map((field) =>
      //       field.label === "kidneySide" ? { ...field, hide: false } : field
      //     )
      //   );
      // }
      // if (res.anyOtherMassFelt.toLowerCase() === "yes") {
      //   setAnthropometryFields((prevFields) =>
      //     prevFields.map((field) =>
      //       field.label === "otherMassDescription"
      //         ? { ...field, hide: false }
      //         : field
      //     )
      //   );
      // }
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // handleAnthropometryFieldChange(name, value, setAnthropometryFields);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm(formData, anthropometryFields, validationRules);

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      handleCreate(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5 bg-white">
      <PatientForm
        formTitle="Anthropometry"
        formFields={anthropometryFields}
        formErrors={formErrors}
        formData={formData}
        handleInputChange={handleInputChange}
        cancelButton={true}
        handleCancelButtonClick={handleCancel}
      />
    </form>
  );
};

export default Anthropometry;
