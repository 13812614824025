import { useEffect, useState } from 'react';
import fields, {
  validationRules,
} from '../../../../../data/admissions/systemic/fields';
import initialFields from '../../../../../data/admissions/systemic/initialValues';
import SystemicService from '../../../../../services/examinations/SystemicService';
import { handleSystemicFieldChange } from '../../../../../utils/handleConditionalFields';
import { validateForm } from '../../../../../validation/formValidation';
import PatientForm from '../../../Patient/Form/Index';

const Systemic = ({ admissionId, handleCreate, handleCancel }) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [systemicFields, setSystemicFields] = useState(fields);

  useEffect(() => {
    fetchSystemic();
  }, []);

  const fetchSystemic = async () => {
    try {
      const res = await SystemicService.getDetail(admissionId);
      if (res.cardiovascularSystem.toLowerCase() === 'abnormal') {
        setSystemicFields((prevFields) =>
          prevFields.map((field) =>
            field.label === 'cardiovascularSystemAbnormal'
              ? { ...field, hide: false }
              : field,
          ),
        );
      }
      if (res.respiratoryDistress.toLowerCase() === 'abnormal') {
        setSystemicFields((prevFields) =>
          prevFields.map((field) =>
            field.label === 'respiratoryDistressAbnormal'
              ? { ...field, hide: false }
              : field,
          ),
        );
      }

      if (res.ascites.toLowerCase() === 'abnormal') {
        setSystemicFields((prevFields) =>
          prevFields.map((field) =>
            field.label === 'ascitesAbnormal'
              ? { ...field, hide: false }
              : field,
          ),
        );
      }

      if (res.arthritis.toLowerCase() === 'abnormal') {
        setSystemicFields((prevFields) =>
          prevFields.map((field) =>
            field.label === 'arthritisAbnormal'
              ? { ...field, hide: false }
              : field,
          ),
        );
      }

      if (res.dysarthria.toLowerCase() === 'abnormal') {
        setSystemicFields((prevFields) =>
          prevFields.map((field) =>
            field.label === 'dysarthriaAbnormal'
              ? { ...field, hide: false }
              : field,
          ),
        );
      }
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleSystemicFieldChange(name, value, setSystemicFields);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm(formData, systemicFields, validationRules);

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      handleCreate(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5 bg-white">
      <PatientForm
        formTitle="Systemic"
        formFields={systemicFields}
        formErrors={formErrors}
        formData={formData}
        handleInputChange={handleInputChange}
        cancelButton={true}
        handleCancelButtonClick={handleCancel}
      />
    </form>
  );
};

export default Systemic;
