const fields = [
  {
    name: "Diagnosis",
    label: "diagnosis",
    type: "textarea-rich",
    required: false,
  },
];

const validationRules = {
  diagnosis: {},
};

export { validationRules };
export default fields;
