import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Create from "../../../components/Dashboard/Admission/Create/Index";
import Breadcrumb from "../../../components/UI/Breadcrumb/Index";
import fields, { validationRules } from "../../../data/admissions/fields";
import { showError, showUpdated } from "../../../data/messages";
import useServerError from "../../../hooks/useServerError";
import AdmissionService from "../../../services/AdmissionService";

const CreateAdmission = () => {
  const navigate = useNavigate();
  const { updateServerError } = useServerError();
  const [admission, setAdmission] = useState({});
  const [admissionFields, setAdmissionFields] = useState(fields);
  const { patientId } = useParams();
  const { admissionId } = useParams();

  useEffect(() => {
    fetchAdmissionDetail();
  }, [admissionId]);

  const fetchAdmissionDetail = async () => {
    try {
      const res = await AdmissionService.getDetail(patientId, admissionId);
      setAdmission(res);
    } catch (error) {
      if (error.body.statusCode >= 400 && error.body.statusCode < 600) {
        updateServerError(error);
      }
      console.log(error);
    }
  };

  const updateAdmission = async (formData) => {
    try {
      await AdmissionService.update(patientId, admissionId, formData);
      showUpdated("Admission");
      return navigate(
        `/u/patients/${patientId}/admissions/${admissionId}/vitals`
      );
    } catch (error) {
      if (error.body.statusCode >= 400 && error.body.statusCode < 600) {
        updateServerError(error, showError);
      }
      console.log(error);
    }
  };

  return (
    <>
      <Breadcrumb pageName="Update Admission" />
      <div className="flex flex-col gap-10">
        {admission.id && (
          <>
            <span>
              of{" "}
              <Link
                to={`/u/patients/${admission.patient.id}/birth_history`}
                className="text-meta-3"
              >
                {admission.patient.patientName}
              </Link>{" "}
            </span>
            <Create
              patientId={patientId}
              handleAdmissionCreate={updateAdmission}
              initialFields={admission}
              admissionFields={admissionFields}
              validationRules={validationRules}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CreateAdmission;
