import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../data/constants";
import {
  BIRTH_HISTORY_CREATE,
  BIRTH_HISTORY_DETAIL,
} from "../data/constants/api";
import { loadState } from "../utils/localStorage";
import Api from "./Api";

class BirthHistoryService {
  async getDetail(patientId) {
    const res = await Api.get(
      template(BIRTH_HISTORY_DETAIL)({ patientId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(patientId, payload) {
    const res = await Api.post(
      template(BIRTH_HISTORY_DETAIL)({ patientId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async update(patientId, birthHistoryId, payload) {
    const res = await Api.patch(
      template(BIRTH_HISTORY_CREATE)({ patientId, birthHistoryId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new BirthHistoryService();
