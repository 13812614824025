import { useEffect, useState } from "react";
import fields from "../../../../../data/patients/familyHistory/familyHistoryFields";
import initialFields from "../../../../../data/patients/familyHistory/familyHistoryInitialValues";
import FamilyHistoryService from "../../../../../services/FamilyHistoryService";
import { handleFamilyHistoryFieldsChange } from "../../../../../utils/handleConditionalFields";
import { validateForm } from "../../../../../validation/formValidation";
import PatientForm from "../../Form/Index";

const FamilyHistoryCreate = ({
  patientId,
  handleCancel,
  handleFamilyHistorySave,
}) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [familyHistoryFields, setFamilyHistoryFields] = useState(fields);

  useEffect(() => {
    fetchFamilyHistory();
  }, []);

  const fetchFamilyHistory = async () => {
    try {
      const res = await FamilyHistoryService.getDetail(patientId);
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
      if (res.medicalHistory.toLowerCase() === "yes") {
        setFamilyHistoryFields((prevFields) =>
          prevFields.map((field) =>
            field.label === "historyValue" ? { ...field, hide: false } : field
          )
        );
      }
      if (res.similarIllnessInFamilyMember.toLowerCase() === "yes") {
        setFamilyHistoryFields((prevFields) =>
          prevFields.map((field) =>
            field.label === "whatIllness" ? { ...field, hide: false } : field
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFamilyHistoryFieldsChange(name, value, setFamilyHistoryFields);

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm(formData, familyHistoryFields);

    if (Object.keys(errors).length === 0) {
      handleFamilyHistorySave(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5">
            <PatientForm
              formTitle="Family History"
              formFields={familyHistoryFields}
              formErrors={formErrors}
              formData={formData}
              handleInputChange={handleInputChange}
              cancelButton={true}
              handleCancelButtonClick={handleCancel}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default FamilyHistoryCreate;
