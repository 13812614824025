let initialFields = {
  cardiovascularSystem: 'Normal',
  cardiovascularSystemAbnormal: '',
  respiratoryDistress: 'Normal',
  respiratoryDistressAbnormal: '',
  ascites: 'Normal',
  ascitesAbnormal: '',
  arthritis: 'Normal',
  arthritisAbnormal: '',
  dysarthria: 'Normal',
  dysarthriaAbnormal: '',
};

export default initialFields;
