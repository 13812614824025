import { useEffect, useState } from 'react';
import fields, {
  validationRules,
} from '../../../../../data/admissions/vitals/fields';
import initialFields from '../../../../../data/admissions/vitals/initialValues';
import VitalsService from '../../../../../services/examinations/VitalsService';
import { validateForm } from '../../../../../validation/formValidation';
import PatientForm from '../../../Patient/Form/Index';

const Vitals = ({ admissionId, handleVitalsCreate, handleCancel }) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [admissionFields, setAdmissionFields] = useState(fields);

  const handleInputChange = (e, extra = null) => {
    const { name, value } = e.target;
    // handleVitalsFieldChange(name, value, setAdmissionFields);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  useEffect(() => {
    fetchVitals();
  }, []);

  const fetchVitals = async () => {
    try {
      const res = await VitalsService.getDetail(admissionId);
      // if (res.peripheralPulses.toLowerCase() === "no") {
      //   setAdmissionFields((prevFields) =>
      //     prevFields.map((field) =>
      //       field.label === "peripheralPulsesNo"
      //         ? { ...field, hide: false }
      //         : field
      //     )
      //   );
      // }
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm(formData, admissionFields, validationRules);

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      handleVitalsCreate(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5 bg-white">
      <PatientForm
        formTitle="Vital"
        formFields={admissionFields}
        formErrors={formErrors}
        formData={formData}
        handleInputChange={handleInputChange}
        cancelButton={true}
        handleCancelButtonClick={handleCancel}
      />
    </form>
  );
};

export default Vitals;
