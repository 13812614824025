import { lazy, Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Loader from "./components/UI/Loader/Index";
import AdmissionDetail from "./pages/dashboard/Admissions/Detail";
import AdmissionEdit from "./pages/dashboard/Admissions/Edit";
const DischargePrint = lazy(() => import("./pages/dashboard/Print/patient"));
const InvestigationsPrint = lazy(() => import("./pages/dashboard/Print/investigations"));
const DefaultLayout = lazy(() => import("./layout/DashboardLayout"));
const Home = lazy(() => import("./pages/Index"));
const Login = lazy(() => import("./pages/auth/login/Index"));
const Dashboard = lazy(() => import("./pages/dashboard/Index"));
const PatientList = lazy(() => import("./pages/dashboard/Patients/Index"));
const PatientCreate = lazy(() => import("./pages/dashboard/Patients/Create"));
const PatientDetail = lazy(() => import("./pages/dashboard/Patients/Detail"));
const PatientEdit = lazy(() => import("./pages/dashboard/Patients/Edit"));
const AdmissionCreate = lazy(() =>
  import("./pages/dashboard/Admissions/Create")
);
const AdmissionList = lazy(() => import("./pages/dashboard/Admissions/Index"));
const UserList = lazy(() => import("./pages/dashboard/Users/Index"));
const UserCreate = lazy(() => import("./pages/dashboard/Users/Create"));
const Profile = lazy(() => import("./pages/dashboard/Profile/Index"));

const App = () => {
  return (
    <>
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/u" element={<DefaultLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="patients" element={<PatientList />} />
              <Route path="profile" element={<Profile />} />
              <Route path="patients/create" element={<PatientCreate />} />
              <Route path="patients/:patientId" element={<PatientDetail />} />
              <Route
                path="patients/:patientId/edit"
                element={<PatientEdit />}
              />
              <Route
                path="patients/:patientId/birth_history"
                element={<PatientDetail />}
              />
              <Route
                path="patients/:patientId/birth_details"
                element={<PatientDetail />}
              />
              <Route
                path="patients/:patientId/nutritional_history"
                element={<PatientDetail />}
              />
              <Route
                path="patients/:patientId/family_history"
                element={<PatientDetail />}
              />
              <Route
                path="patients/:patientId/social_history"
                element={<PatientDetail />}
              />
              <Route
                path="patients/:patientId/immunisation_history"
                element={<PatientDetail />}
              />
              <Route
                path="patients/:patientId/admissions/create"
                element={<AdmissionCreate />}
              />
              <Route
                path="patients/:patientId/admissions/:admissionId/vitals"
                element={<AdmissionDetail />}
              />
              <Route
                path="patients/:patientId/admissions/:admissionId/head_to_toe"
                element={<AdmissionDetail />}
              />
              <Route
                path="patients/:patientId/admissions/:admissionId/anthropometry"
                element={<AdmissionDetail />}
              />
              {/* <Route
                path="patients/:patientId/admissions/:admissionId/carnial_nerve"
                element={<AdmissionDetail />}
              />
              <Route
                path="patients/:patientId/admissions/:admissionId/motor"
                element={<AdmissionDetail />}
              /> */}
              <Route
                path="patients/:patientId/admissions/:admissionId/systemic"
                element={<AdmissionDetail />}
              />
              <Route
                path="patients/:patientId/admissions/:admissionId/developmental"
                element={<AdmissionDetail />}
              />
              <Route
                path="patients/:patientId/admissions/:admissionId/edit"
                element={<AdmissionEdit />}
              />
              <Route path="admissions" element={<AdmissionList />} />
              <Route path="users" element={<UserList />} />
              <Route path="users/create" element={<UserCreate />} />
            </Route>
            <Route
              path="/discharge/patient/:patientId/admission/:admissionId/printpdf"
              element={<DischargePrint />}
            ></Route>
            <Route
              path="/patient/:patientId/admission/:admissionId/investigationspdf"
              element={<InvestigationsPrint />}
            ></Route>
          </Routes>
        </Suspense>
      </Router>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 10000,
          success: {
            style: {
              background: "#ffffff",
              color: "green",
            },
          },
          error: {
            style: {
              background: "red",
            },
          },
        }}
      />
    </>
  );
};

export default App;
