import React, { useEffect, useState } from 'react';
import ImmunizationService from '../../../../../services/ImmunizationService';
import EmptyState from '../../../Admission/Sections/EmptyState';

const ImmunisationHistoryView = ({ patientId, handleEditClick }) => {
  const [immuneHistory, setImmuneHistory] = useState({});

  useEffect(() => {
    fetchImmuneHistory();
  }, []);

  const fetchImmuneHistory = async () => {
    try {
      const res = await ImmunizationService.getDetail(patientId);
      setImmuneHistory(res);
    } catch (error) {
      console.error(error);
    }
  };

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  if (!immuneHistory.id) {
    return (
      <EmptyState
        handleEditClick={handleEditClick}
        entityName="Immunization History"
      />
    );
  }

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-6 gap-9 p-3">
              {renderField('BCG', immuneHistory.bcg)}
              {renderField('Pentavac', immuneHistory.pentavacOPV)}
              {immuneHistory.pentavacOPV === 'Yes' &&
                renderField('Pentavac doses', immuneHistory.pentavacDoses)}
              {renderField('OPV', immuneHistory.opv)}
              {immuneHistory.opv === 'Yes' &&
                renderField('OPV doses', immuneHistory.opvDoses)}
              {renderField('Rota', immuneHistory.rota)}
              {immuneHistory.rota === 'Yes' &&
                renderField('Rota Doses', immuneHistory.rotaDoses)}
              {renderField('PCV 10', immuneHistory.pcv10)}
              {immuneHistory.pcv_10 === 'Yes' &&
                renderField('PCV 10 Doses', immuneHistory.pcv10Doses)}
              {renderField('FIPV', immuneHistory.fipv)}
              {immuneHistory.fipv === 'Yes' &&
                renderField('FIPV Doses', immuneHistory.fipvDoses)}
              {renderField('MR', immuneHistory.mr)}
              {immuneHistory.mr === 'Yes' &&
                renderField('MR Doses', immuneHistory.mrDoses)}
              {renderField('JE', immuneHistory.je)}
              {renderField('TCV', immuneHistory.tcv)}
              {renderField('Others', immuneHistory.others)}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Immunization History
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImmunisationHistoryView;
