import React from "react";
import VitalsService from "../../../../../services/examinations/VitalsService";
import Vitals from "./Index";
import VitalsView from "./View";

const VitalsComponent = ({
  admissionId,
  editVitals,
  setEditVitals,
  handleCreate,
}) => {
  return (
    <>
      {editVitals ? (
        <Vitals
          admissionId={admissionId}
          handleCancel={() => setEditVitals(false)}
          handleVitalsCreate={(formData) =>
            handleCreate(
              formData,
              VitalsService,
              "Vitals examination",
              setEditVitals
            )
          }
        />
      ) : (
        <VitalsView
          admissionId={admissionId}
          handleEditClick={(e) => {
            e.preventDefault();
            setEditVitals(true);
          }}
        />
      )}
    </>
  );
};

export default VitalsComponent;
