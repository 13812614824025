import symptoms from '../constants/symptoms';

const fields = [
  {
    label: 'complaints',
    name: 'Other Complaints',
    type: 'checkbox',
    options: symptoms,
    required: false,
  },
  {
    label: 'presentIllness',
    name: 'Present Illness',
    type: 'textarea',
    required: true,
  },
  {
    label: 'pastMedicalHistory',
    name: 'Past Medical History',
    type: 'text',
    required: true,
  },
];

let validationRules = {
  pastMedicalHistory: { required: true },
  presentIllness: { required: true },
};

export default fields;
export { validationRules };
