import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../data/constants";
import {
  IMMUNE_HISTORY_DETAIL,
  IMMUNE_HISTORY_UPDATE,
} from "../data/constants/api";
import { loadState } from "../utils/localStorage";
import Api from "./Api";

class ImmunizationService {
  async getDetail(patientId) {
    const res = await Api.get(
      template(IMMUNE_HISTORY_DETAIL)({ patientId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(patientId, payload) {
    const res = await Api.post(
      template(IMMUNE_HISTORY_DETAIL)({ patientId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async update(patientId, immuneHistoryId, payload) {
    const res = await Api.patch(
      template(IMMUNE_HISTORY_UPDATE)({ patientId, immuneHistoryId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new ImmunizationService();
