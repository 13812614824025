import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../../data/constants";
import {
    SYSTEMIC_DETAIL,
    SYSTEMIC_UPDATE,
} from "../../data/constants/api";
import { loadState } from "../../utils/localStorage";
import Api from "../Api";

class SystemicService {
  async getDetail(admissionId) {
    const res = await Api.get(
      template(SYSTEMIC_DETAIL)({ admissionId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(admissionId, payload) {
    const res = await Api.post(
      template(SYSTEMIC_DETAIL)({ admissionId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async update(admissionId, examSystemicId, payload) {
    const res = await Api.patch(
      template(SYSTEMIC_UPDATE)({ admissionId, examSystemicId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new SystemicService();
