import { useEffect, useState } from "react";

import { changeToNepaliDate } from "../../../../utils";

const DateField = ({ handleInputChange, formData, formErrors, field }) => {
  const [bsDate, setBsDate] = useState("");

  useEffect(() => {
    setBsDate(changeToNepaliDate(formData[field.label]));
  }, []);

  const handleDateChange = (e) => {
    setBsDate(changeToNepaliDate(e.target.value));
    handleInputChange(e);
  };

  return (
    <>
      <input
        type={field.type}
        name={field.label}
        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3"
        value={formData[field.label]}
        onChange={(e) => {
          handleDateChange(e);
        }}
      />
      {formErrors[field.label] && (
        <p className="text-danger mt-1 text-sm">{formErrors[field.label]}</p>
      )}
      <span className="text-meta-3 text-sm">{bsDate}</span>
    </>
  );
};

export default DateField;
