import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../../data/constants";
import {
  CREATE_INVESTIGATION,
  DELETE_INVESTIGATION,
  DELETE_INVESTIGATION_IMAGE,
  FETCH_INVESTIGATION,
  FETCH_INVESTIGATION_IMAGES,
} from "../../data/constants/api";
import { loadState } from "../../utils/localStorage";
import Api from "../Api";

class InvestigationService {
  async get(admissionId) {
    const res = await Api.get(
      template(FETCH_INVESTIGATION)({ admissionId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(admissionId, params) {
    const res = await Api.post(
      template(CREATE_INVESTIGATION)({ admissionId }),
      params,
      "multipart/form-data",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async fetchImages(admissionId, investigationType) {
    const res = await Api.get(
      template(FETCH_INVESTIGATION_IMAGES)({ admissionId, investigationType }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async delete(id) {
    const res = await Api.delete(
      template(DELETE_INVESTIGATION)({ id }),
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async deleteImage(imageId) {
    const res = await Api.delete(
      template(DELETE_INVESTIGATION_IMAGE)({ imageId }),
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new InvestigationService();
