import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../../data/constants";
import { DISCHARGE_DETAIL, DISCHARGE_UPDATE } from "../../data/constants/api";
import { loadState } from "../../utils/localStorage";
import Api from "../Api";

class DischargeService {
  async getDetail(admissionId) {
    const res = await Api.get(
      template(DISCHARGE_DETAIL)({ admissionId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(admissionId, payload) {
    const res = await Api.post(
      template(DISCHARGE_DETAIL)({ admissionId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async update(admissionId, dischargeId, payload) {
    const res = await Api.patch(
      template(DISCHARGE_UPDATE)({ admissionId, dischargeId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new DischargeService();
