const fields = [
  {
    label: "anteriorFontanelle",
    name: "Anterior Fontanelle",
    type: "select",
    options: ["NA", "Open", "Closed", "Bulging"],
    required: true,
  },
  {
    label: "ent",
    name: "HENT",
    type: "select",
    options: ["Normal", "Abnormal"],
    required: true,
  },
  {
    label: "entAbnormal",
    name: "HENT Abnormal",
    type: "text",
    hide: true,
  },
  {
    label: "obviousCongenitalMalformations",
    name: "Obvious congenital Malformations",
    type: "text",
  },
  {
    label: "dysmorphicFeatures",
    name: "Dysmorphic Features",
    type: "text",
  },
  {
    label: "rash",
    name: "Rash",
    type: "select",
    options: ["Yes", "No"],
    required: true,
  },
  {
    label: "pallor",
    name: "Pallor",
    type: "select",
    options: ["Yes", "No"],
    required: true,
  },
  {
    label: "icterus",
    name: "Icterus",
    type: "select",
    options: ["Yes", "No"],
    required: true,
  },
  {
    label: "clubbing",
    name: "Clubbing",
    type: "select",
    options: ["Yes", "No"],
    required: true,
  },
  {
    label: "lymphadenopathy",
    name: "Lymphadenopathy",
    type: "select",
    options: ["Significant", "Insignificant"],
    required: true,
  },
  {
    label: "pedalEdema",
    name: "Pedal Edema",
    type: "select",
    options: ["Yes", "No"],
    required: true,
  },
  {
    label: "bonyTenderness",
    name: "Bony tenderness",
    type: "select",
    options: ["Absent", "Present"],
    required: true,
  },
  {
    label: "otherSpecificFindings",
    name: "Other Specific Findings",
    type: "text",
  },
];

let validationRules = {
  anteriorFontanelle: { required: true },
  ent: { required: true },
  obviousCongenitalMalformations: {},
  dysmorphicFeatures: {},
  rash: { required: true },
  pallor: { required: true },
  icterus: { required: true },
  clubbing: { required: true },
  lymphadenopathy: { required: true },
  pedalEdema: { required: true },
  bonyTenderness: { required: true },
  otherSpecificFindings: {},
};

export default fields;
export { validationRules };
