const handleBirthHistoryFieldChange = (name, value, setBirthHistoryFields) => {
  switch (name) {
    case 'pregnancyEvent':
      const hidePregnancyEventYes = value !== 'Yes';
      setBirthHistoryFields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'pregnancyEventYes'
            ? { ...field, hide: hidePregnancyEventYes }
            : field,
        ),
      );
      break;
    case 'placeOfDelivery':
      const hidePlaceOfDeliveryOther = value !== 'Other';
      setBirthHistoryFields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'placeOfDeliveryOther'
            ? { ...field, hide: hidePlaceOfDeliveryOther }
            : field,
        ),
      );
      break;
    case 'typeOfDelivery':
      setBirthHistoryFields((prevFields) =>
        prevFields.map((field) => {
          switch (field.label) {
            case 'assistedVaginal':
              return { ...field, hide: value !== 'Assisted vaginal' };
            case 'typeOfDeliveryOther':
              return { ...field, hide: value !== 'Other' };
            default:
              return field;
          }
        }),
      );
      break;
    default:
      break;
  }
};

const handleIsNeonatalProblems = (name, value, setBirthDetailsFields) => {
  switch (name) {
    case 'isNeonatalProblems':
      const hideNeoNatal = value !== 'Yes';
      setBirthDetailsFields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'neonatalProblems'
            ? { ...field, hide: hideNeoNatal }
            : field,
        ),
      );
      break;
    default:
      break;
  }
};

const handleFamilyHistoryFieldsChange = (name, value, setFamilyHistory) => {
  switch (name) {
    case 'medicalHistory':
      const hideHistoryValue = value !== 'Yes';
      setFamilyHistory((prevFields) =>
        prevFields.map((field) =>
          field.label === 'historyValue'
            ? { ...field, hide: hideHistoryValue }
            : field,
        ),
      );
      break;
    case 'similarIllnessInFamilyMember':
      const hideWhatIllness = value !== 'Yes';
      setFamilyHistory((prevFields) =>
        prevFields.map((field) =>
          field.label === 'whatIllness'
            ? { ...field, hide: hideWhatIllness }
            : field,
        ),
      );
      break;
    default:
      break;
  }
};

const handleImmuneHistoryFieldChange = (
  name,
  value,
  setImmunizationHistory,
) => {
  const updateImmunizationHistory = (fieldName, value) => {
    setImmunizationHistory((prevFields) =>
      prevFields.map((field) =>
        field.label === `${fieldName}Doses`
          ? { ...field, hide: value !== 'Yes' }
          : field,
      ),
    );
  };

  let doseFieldName;
  switch (name) {
    case 'pentavacOPV':
      doseFieldName = 'pentavac';
      break;
    case 'opv':
      doseFieldName = 'opv';
      break;
    case 'rota':
      doseFieldName = 'rota';
      break;
    case 'pcv10':
      doseFieldName = 'pcv10';
      break;
    case 'fipv':
      doseFieldName = 'fipv';
      break;
    case 'mr':
      doseFieldName = 'mr';
      break;
    default:
      break;
  }

  if (doseFieldName) {
    updateImmunizationHistory(doseFieldName, value);
  }
};

// const handleVitalsFieldChange = (name, value, setVitalsfields) => {
//   switch (name) {
//     case 'peripheralPulses':
//       const hidePeripheralPulsesNo = value !== 'No';
//       setVitalsfields((prevFields) =>
//         prevFields.map((field) =>
//           field.label === 'peripheralPulsesNo'
//             ? { ...field, hide: hidePeripheralPulsesNo }
//             : field,
//         ),
//       );
//       break;
//     default:
//       break;
//   }
// };

const handleHeadToToeFieldChange = (name, value, setHeadtoToeFields) => {
  switch (name) {
    case 'ent':
      const hideHentAbnormal = value !== 'Abnormal';
      setHeadtoToeFields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'entAbnormal'
            ? { ...field, hide: hideHentAbnormal }
            : field,
        ),
      );
      break;
    default:
      break;
  }
};

const handleSystemicFieldChange = (name, value, setAnthropometryfields) => {
  switch (name) {
    case 'cardiovascularSystem':
      const hideAbnormal = value !== 'Abnormal';
      setAnthropometryfields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'cardiovascularSystemAbnormal'
            ? { ...field, hide: hideAbnormal }
            : field,
        ),
      );
      break;
    case 'respiratoryDistress':
      const hideRespiratoryDistressAbnormal = value !== 'Abnormal';
      setAnthropometryfields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'respiratoryDistressAbnormal'
            ? { ...field, hide: hideRespiratoryDistressAbnormal }
            : field,
        ),
      );
      break;
    case 'ascites':
      const hideAscitesAbnormal = value !== 'Abnormal';
      setAnthropometryfields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'ascitesAbnormal'
            ? { ...field, hide: hideAscitesAbnormal }
            : field,
        ),
      );
      break;

    case 'arthritis':
      const hideArthritisAbnormal = value !== 'Abnormal';
      setAnthropometryfields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'arthritisAbnormal'
            ? { ...field, hide: hideArthritisAbnormal }
            : field,
        ),
      );
      break;

    case 'dysarthria':
      const hideDysarthriaAbnormal = value !== 'Abnormal';
      setAnthropometryfields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'dysarthriaAbnormal'
            ? { ...field, hide: hideDysarthriaAbnormal }
            : field,
        ),
      );
      break;
    default:
      break;
  }
};

const handleDevelopmentalFieldChange = (
  name,
  value,
  setDevelopmentalFields,
) => {
  switch (name) {
    case 'assessment':
      const hideAbnormal = value !== 'Abnormal';
      setDevelopmentalFields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'assessmentAbnormal'
            ? { ...field, hide: hideAbnormal }
            : field,
        ),
      );
      break;
    default:
      break;
  }
};

const handleMotorFieldChange = (name, value, setMotorFields) => {
  switch (name) {
    case 'sensoryExamination':
      const hideSensoryDetail = value !== 'Abnormal';
      setMotorFields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'sensoryExaminationDetails'
            ? { ...field, hide: hideSensoryDetail }
            : field,
        ),
      );
      break;
    default:
      break;
  }
};

const handleDischargeFieldChange = (name, value, setDischargeFields) => {
  switch (name) {
    case 'outcome':
      const hideOutcomeTiming = value !== 'Expired';
      setDischargeFields((prevFields) =>
        prevFields.map((field) =>
          field.label === 'outcomeTiming'
            ? { ...field, hide: hideOutcomeTiming }
            : field,
        ),
      );
      break;
    default:
      break;
  }
};

export default handleBirthHistoryFieldChange;
export {
  handleDevelopmentalFieldChange,
  handleDischargeFieldChange,
  handleFamilyHistoryFieldsChange,
  handleHeadToToeFieldChange,
  handleImmuneHistoryFieldChange,
  handleIsNeonatalProblems,
  handleMotorFieldChange,
  handleSystemicFieldChange,
};
