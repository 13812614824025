import React from "react";
import CheckBox from "../../../../UI/CheckBox/Index";

const InvestigationOptions = ({
  options,
  selectedInvestigationsFields,
  handleInvestigationInputChange,
  investigationName,
}) => {
  return (
    <>
      {options.map((option, optionIndex) => (
        <div key={optionIndex} className="mb-1 text-xs">
          <CheckBox
            option={option}
            selected={selectedInvestigationsFields[investigationName]}
            handleChange={(e) =>
              handleInvestigationInputChange(e, investigationName, option.name)
            }
          />
        </div>
      ))}
    </>
  );
};

export default InvestigationOptions;
