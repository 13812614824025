let initialFields = {
  anteriorFontanelle: 'NA',
  ent: 'Normal',
  entAbnormal: '',
  obviousCongenitalMalformations: '',
  dysmorphicFeatures: '',
  rash: 'No',
  pallor: 'No',
  icterus: 'No',
  clubbing: 'No',
  lymphadenopathy: 'Insignificant',
  pedalEdema: 'No',
  bonyTenderness: 'Absent',
  otherSpecificFindings: '',
};

export default initialFields;
