import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../data/constants";
import {
  ADMISSION_CREATE,
  ADMISSION_DELETE,
  ADMISSION_DETAIL,
  DOWNLOAD_PDF,
  LIST_ADMISSION,
  LIST_ALL_ADMISSION,
} from "../data/constants/api";
import { loadState } from "../utils/localStorage";
import Api from "./Api";

class AdmissionService {
  /**
   *
   * @param {*} patientId
   * @returns Admissions
   */
  async get(patientId) {
    const res = await Api.get(
      template(LIST_ADMISSION)({ patientId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  /**
   * Return all the admissions
   * @returns Admissions
   */
  async getAll(params = {}) {
    const res = await Api.get(
      LIST_ALL_ADMISSION,
      params,
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  /**
   *
   * @param {*} patientId
   * @param {*} admissionId
   * @returns Admission
   */
  async getDetail(patientId, admissionId) {
    const res = await Api.get(
      template(ADMISSION_DETAIL)({ patientId, admissionId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  /**
   *
   * @param {*} patientId
   * @param {*} payload
   * @returns Admission
   */
  async create(patientId, payload) {
    const res = await Api.post(
      template(ADMISSION_CREATE)({ patientId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  /**
   *
   * @param {*} patientId
   * @param {*} admissionId
   * @param {*} payload
   * @returns Admission
   */
  async update(patientId, admissionId, payload) {
    const res = await Api.patch(
      template(ADMISSION_DETAIL)({ patientId, admissionId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  /**
   *
   * @param {*} patientId
   * @param {*} admissionId
   * @returns Admission
   */
  async delete(id) {
    const res = await Api.delete(
      template(ADMISSION_DELETE)({ id }),
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res;
  }

  async downloadPdf(patientId, admissionId) {
    const res = await Api.getPdf(
      template(DOWNLOAD_PDF)({ patientId, admissionId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res;
  }
}

export default new AdmissionService();
