let initialFields = {
  fever: 'Normal',
  heartRate: 'Normal',
  peripheralPulses: 'Yes',
  // peripheralPulsesNo: '',
  bp: 'Normal',
  rr: 'Normal',
  crt: 'Normal',
  o2Satuaration: 'Normal',
};

export default initialFields;
