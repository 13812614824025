import React from "react";
import HeadToToeService from "../../../../../services/examinations/HeadToToeService";
import HeadToToeExamination from "./Index";
import HeadToToeView from "./View";

const HeadToToeComponent = ({
  admissionId,
  editHeadToToe,
  setEditHeadToToe,
  handleCreate,
}) => {
  return (
    <>
      {editHeadToToe ? (
        <HeadToToeExamination
          admissionId={admissionId}
          handleCancel={() => setEditHeadToToe(false)}
          handleCreate={(formData) =>
            handleCreate(
              formData,
              HeadToToeService,
              "Head to toe examination",
              setEditHeadToToe
            )
          }
        />
      ) : (
        <HeadToToeView
          admissionId={admissionId}
          handleEditClick={(e) => {
            e.preventDefault();
            setEditHeadToToe(true);
          }}
        />
      )}
    </>
  );
};

export default HeadToToeComponent;
