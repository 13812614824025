import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  investigationFields,
  investigationNames,
  options,
} from '../../../../data/investigations';
import {
  genericSuccess,
  showDeleted,
  showError,
  showSuccess,
  showUpdated,
} from '../../../../data/messages';
import useServerError from '../../../../hooks/useServerError';
import AdmissionService from '../../../../services/AdmissionService';
import InvestigationService from '../../../../services/admission/InvestigationService';
import AdmissionDetail from '../Sections/Detail/Index';
import Diagnosis from '../Sections/Diagnosis/Index';
import DischargeComponent from '../Sections/Discharge/DischargeComponent';
import Examinations from '../Sections/Examinations';
import Investigations from '../Sections/Investigations/Investigations';
import PatientHistory from '../Sections/PatientHistory/PatientHistory';
import TreatmentReceived from '../Sections/TreatmentReceived/Index';

const Detail = ({ admission }) => {
  const { patientId } = useParams();
  const [editVitals, setEditVitals] = useState(false);
  const [editHeadToToe, setEditHeadToToe] = useState(false);
  const [editAnthropometry, setEditAntropometry] = useState(false);
  const [editDischarge, setEditDischarge] = useState(false);
  const [editSystemic, setEditSystemic] = useState(false);
  const [editDevelopmental, setEditDevelopmental] = useState(false);
  const [selected, setSelected] = useState(
    admission.selectedInvestigations ?? [],
  );
  const [selectedInvestigationsFields, setSelectedInvestigationsFields] =
    useState({
      hematology: [],
      biochemistry: [],
      pathology: [],
      microbiology: [],
      endoscopy: [],
      imaging: [],
      physiometry: [],
    });
  const [allInvestigationFields, setAllInvestigationFields] =
    useState(investigationFields);
  const [investigations, setInvestigations] = useState(options);
  const { updateServerError } = useServerError();
  const [showPrintButton, setShowPrintButton] = useState(false);

  useEffect(() => {
    setInvestigations(
      options.map((optionValue) => ({
        ...optionValue,
        hide: !selected.includes(optionValue.name),
      })),
    );
    fetchInvestigations();
  }, []);

  const handleDiagnosisUpdate = async (params) => {
    try {
      await AdmissionService.update(admission.patientId, admission.id, params);
      showUpdated('Diagnosis');
    } catch (error) {
      console.error(error);
      updateServerError(error, showError);
    }
  };

  const updateInvestigationsForField = (
    fieldName,
    allInvestigationFields,
    res,
    setAllInvestigationFields,
  ) => {
    const updatedInvestigations = allInvestigationFields[fieldName].map(
      (investigation) => {
        const matchedField = res.investigations.find(
          (field) => field.label === investigation.label,
        );
        if (matchedField) {
          return { ...investigation, ...matchedField, hide: false };
        } else {
          return investigation;
        }
      },
    );

    setAllInvestigationFields((prevFields) => {
      const updatedFields = {
        ...prevFields,
        [fieldName]: updatedInvestigations,
      };
      return updatedFields;
    });
  };

  const fetchInvestigations = async () => {
    try {
      const res = await InvestigationService.get(admission.id);
      setShowPrintButton(res?.investigations?.length > 0 ? true : false);
      if (res?.investigations?.length > 0) {
        investigationNames.forEach((fieldName) => {
          updateInvestigationsForField(
            fieldName,
            allInvestigationFields,
            res,
            setAllInvestigationFields,
          );
        });
        const selectedFieldsCopy = {
          hematology: [],
          biochemistry: [],
          pathology: [],
          microbiology: [],
          endoscopy: [],
          imaging: [],
          physiometry: [],
        };
        const groupedNames = res.investigations.reduce((acc, investigation) => {
          const { name, investigationType } = investigation;
          if (!acc[investigationType]) {
            acc[investigationType] = [];
          }
          if (!acc[investigationType].includes(name)) {
            acc[investigationType].push(name);
          }
          return acc;
        }, selectedFieldsCopy);
        setSelectedInvestigationsFields(groupedNames);
      }
    } catch (error) {
      updateServerError(error, showError);
      console.error(error);
    }
  };

  const handleInputChange = (e, extra = null) => {
    if (e.target.type === 'checkbox') {
      const label = extra;
      const isChecked = e.target.checked;

      setSelected((preSelected) => {
        if (isChecked) {
          return [...preSelected, label];
        } else {
          return preSelected.filter((selected) => selected !== label);
        }
      });
    }
  };

  const handleCreate = async (formData, service, entityName, setEditState) => {
    try {
      if (formData.admissionId && formData.id) {
        await service.update(admission.id, parseInt(formData.id), formData);
        showUpdated(entityName);
      } else {
        await service.create(admission.id, formData);
        showSuccess(entityName);
      }

      setEditState(false);
    } catch (error) {
      updateServerError(error, showError);
      console.log(error);
    }
  };

  const handleInvestigationSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!selected.length) {
        showError('Please select at least one investigation!');
        return;
      }

      await AdmissionService.update(patientId, admission.id, {
        selectedInvestigations: selected,
      });
      genericSuccess('Added the Investigation Types!');
      const updatedOptions = options.map((optionValue) => ({
        ...optionValue,
        hide: !selected.includes(optionValue.name),
      }));
      setInvestigations(updatedOptions);
    } catch (error) {
      updateServerError(error, showError);
      console.error(error);
    }
  };

  const handleInvestigationFormsubmit = async (
    event,
    investigationName,
    selectedFiles = null,
    cb,
  ) => {
    event.preventDefault();
    try {
      const filteredFields = allInvestigationFields[investigationName].filter(
        (field) => !field.hide && field.value !== null,
      );

      if (filteredFields.length < 1) {
        showError(`Please add at least one value for the ${investigationName}`);
      } else {
        const formData = new FormData();
        filteredFields.forEach((data, index) => {
          Object.entries(data).forEach(([key, value]) => {
            formData.append(`investigationData[${index}][${key}]`, value);
          });
        });

        if (selectedFiles !== null) {
          selectedFiles.forEach((file) => {
            formData.append(`investigationImages`, file, file.name);
          });
        }

        if (cb && selectedFiles !== null) {
          cb();
        }
        const res = await InvestigationService.create(admission.id, formData);
        updateInvestigationsForField(
          investigationName,
          allInvestigationFields,
          res?.data,
          setAllInvestigationFields,
        );
        showUpdated(investigationName);
      }
    } catch (error) {
      updateServerError(error, showError);
      console.error(error);
    }
  };

  const handleInvestigationValueChange = (
    event,
    fieldName,
    investigationName,
  ) => {
    event.preventDefault();

    setAllInvestigationFields((prevFields) => {
      const updatedFields = {
        ...prevFields,
        [investigationName]: prevFields[investigationName].map((field) =>
          field.name === fieldName
            ? { ...field, value: event.target.value }
            : field,
        ),
      };

      return updatedFields;
    });
  };

  const handleInvestigationInputChange = (event, investigationName, label) => {
    if (event.target.type === 'checkbox') {
      const isChecked = event.target.checked;

      if (isChecked) {
        setSelectedInvestigationsFields((prevFields) => {
          const updatedFields = {
            ...prevFields,
            [investigationName]: [...prevFields[investigationName], label],
          };

          return updatedFields;
        });
        setAllInvestigationFields((prevFields) => {
          const updatedFields = {
            ...prevFields,
            [investigationName]: prevFields[investigationName].map((field) =>
              field.name === label ? { ...field, hide: false } : field,
            ),
          };

          return updatedFields;
        });
      } else {
        if (
          allInvestigationFields[investigationName].filter(
            (field) => field.name === label,
          )[0]?.id
        ) {
          handleDeleteInvestigation(
            allInvestigationFields[investigationName].filter(
              (field) => field.name === label,
            )[0]?.id,
          );
        }
        setSelectedInvestigationsFields((prevFields) => {
          const updatedFields = {
            ...prevFields,
            [investigationName]: prevFields[investigationName].filter(
              (item) => item !== label,
            ),
          };
          return updatedFields;
        });
        setAllInvestigationFields((prevFields) => {
          const updatedFields = {
            ...prevFields,
            [investigationName]: prevFields[investigationName].map((field) =>
              field.name === label ? { ...field, hide: true } : field,
            ),
          };

          return updatedFields;
        });
      }
    }
  };

  const handleDeleteInvestigation = async (investigationId) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this image?',
    );

    if (confirmDelete) {
      try {
        await InvestigationService.delete(investigationId);
        showDeleted('Investigation');
      } catch (error) {
        updateServerError(error, showError);
        console.error(error);
      }
    }
  };

  return (
    <div className="grid gap-9">
      <AdmissionDetail patientId={patientId} admission={admission} />
      <PatientHistory admission={admission} />
      <Examinations
        {...{
          patientId,
          admission,
          editVitals,
          setEditVitals,
          editHeadToToe,
          setEditHeadToToe,
          editAnthropometry,
          setEditAnthropometry: setEditAntropometry,
          editSystemic,
          setEditSystemic,
          editDevelopmental,
          setEditDevelopmental,
          handleCreate,
        }}
      />

      {admission?.id && (
        <Investigations
          admission={admission}
          showPrintButton={showPrintButton}
          investigations={investigations}
          allInvestigationFields={allInvestigationFields}
          selectedInvestigationsFields={selectedInvestigationsFields}
          setSelectedInvestigationsFields={setSelectedInvestigationsFields}
          handleInvestigationFormsubmit={handleInvestigationFormsubmit}
          handleInvestigationInputChange={handleInvestigationInputChange}
          handleInvestigationValueChange={handleInvestigationValueChange}
          handleInvestigationSubmit={handleInvestigationSubmit}
          options={options}
          selected={selected}
          handleInputChange={handleInputChange}
        />
      )}

      <Diagnosis
        admission={admission}
        handleDiagnosisUpdate={handleDiagnosisUpdate}
      />
      <TreatmentReceived
        admission={admission}
        handleDiagnosisUpdate={handleDiagnosisUpdate}
      />
      <DischargeComponent
        admission={admission}
        editDischarge={editDischarge}
        setEditDischarge={setEditDischarge}
        handleCreate={handleCreate}
      />
    </div>
  );
};

export default Detail;
