import React, { useEffect, useState } from 'react';
import BirthHistoryService from '../../../../../services/BirthHistoryService';
import EmptyState from '../../../Admission/Sections/EmptyState';

const BirthHistory = ({ patientId, handleEditClick }) => {
  const [birthHistory, setBirthHistory] = useState({});

  useEffect(() => {
    fetchBirthHistory();
  }, []);

  const fetchBirthHistory = async () => {
    try {
      const res = await BirthHistoryService.getDetail(patientId);
      setBirthHistory(res);
    } catch (error) {
      console.error(error);
    }
  };

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  if (!birthHistory.id) {
    return (
      <EmptyState
        handleEditClick={handleEditClick}
        entityName="Birth History"
      />
    );
  }

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Pregnancy Event', birthHistory.pregnancyEvent)}
              {birthHistory.pregnancyEvent === 'Yes' &&
                renderField('Pregnancy Event', birthHistory.pregnancyEventYes)}
              {renderField('Place of Delivery', birthHistory.placeOfDelivery)}
              {birthHistory.placeOfDelivery === 'Other' &&
                renderField('Other Place', birthHistory.placeOfDeliveryOther)}
              {renderField('Type of Delivery', birthHistory.typeOfDelivery)}
              {birthHistory.typeOfDelivery === 'Assisted vaginal' &&
                renderField('Assisted Vaginal', birthHistory.assistedVaginal)}
              {birthHistory.typeOfDelivery === 'Other' &&
                renderField('Other', birthHistory.typeOfDeliveryOther)}
              {renderField(
                'Period of Gestation',
                birthHistory.periodOfGestation,
              )}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Birth History
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthHistory;
