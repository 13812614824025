import { useEffect, useState } from "react";
import fields from "../../../../../data/patients/history/birthHistoryFields";
import initialFields from "../../../../../data/patients/history/birthHistoryInitialValues";
import BirthHistoryService from "../../../../../services/BirthHistoryService";
import handleBirthHistoryFieldChange from "../../../../../utils/handleConditionalFields";
import { validateForm } from "../../../../../validation/formValidation";
import PatientForm from "../../Form/Index";

const BirthHistoryCreate = ({
  patientId,
  handleCancel,
  handleBirthHistorySave,
}) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [birthHistoryFields, setBirthHistoryFields] = useState(fields);

  useEffect(() => {
    fetchBirthHistory();
  }, []);

  const fetchBirthHistory = async () => {
    try {
      const res = await BirthHistoryService.getDetail(patientId);
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
      if (res.typeOfDelivery.toLowerCase() === "assisted vaginal") {
        setBirthHistoryFields((prevFields) =>
          prevFields.map((field) =>
            field.label === "assistedVaginal"
              ? { ...field, hide: false }
              : field
          )
        );
      }
      if (res.pregnancyEvent.toLowerCase() === "yes") {
        setBirthHistoryFields((prevFields) =>
          prevFields.map((field) =>
            field.label === "pregnancyEventYes"
              ? { ...field, hide: false }
              : field
          )
        );
      }
      if (res.placeOfDelivery.toLowerCase() === "other") {
        setBirthHistoryFields((prevFields) =>
          prevFields.map((field) =>
            field.label === "placeOfDeliveryOther"
              ? { ...field, hide: false }
              : field
          )
        );
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleBirthHistoryFieldChange(name, value, setBirthHistoryFields);

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm(formData, birthHistoryFields);

    if (Object.keys(errors).length === 0) {
      handleBirthHistorySave(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5">
            <PatientForm
              formTitle="Birth History"
              formFields={birthHistoryFields}
              formErrors={formErrors}
              formData={formData}
              handleInputChange={handleInputChange}
              cancelButton={true}
              handleCancelButtonClick={handleCancel}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default BirthHistoryCreate;
