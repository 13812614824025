import React from 'react';
import CheckBox from '../../../../UI/CheckBox/Index';
import Layout from '../Layout';
import InvestigationForm from './InvestigationForm';

const Investigations = ({
  admission,
  investigations,
  allInvestigationFields,
  selectedInvestigationsFields,
  handleInvestigationFormsubmit,
  handleInvestigationInputChange,
  handleInvestigationValueChange,
  handleInvestigationSubmit,
  options,
  selected,
  handleInputChange,
  showPrintButton,
}) => {
  return (
    <div>
      <Layout title="Select Investigations" initialOpen={true}>
        <div className="flex flex-col p-3">
          <form onSubmit={handleInvestigationSubmit}>
            <div className="grid grid-cols-4 gap-4">
              {options.map((option, optionIndex) => (
                <div key={optionIndex}>
                  <CheckBox
                    option={option}
                    selected={selected}
                    handleChange={handleInputChange}
                  />
                </div>
              ))}
            </div>
            <div className="flex justify-end items-end">
              {/* {showPrintButton && 
                <a href={`/patient/${admission.patientId}/admission/${admission.id}/investigationspdf`} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="rounded bg-primary p-3 font-medium text-white">
                    Print Investigations
                  </button>
                </a>
              } */}
              <button
                type="submit"
                className="ml-3 rounded bg-meta-3 p-3 font-medium text-white"
              >
                Get Forms
              </button>
            </div>
          </form>
        </div>
      </Layout>

      {investigations.length > 0 &&
        investigations
          .filter((investigation) => !investigation.hide)
          .map((investigation) => (
            <InvestigationForm
              key={investigation.id}
              admission={admission}
              investigation={investigation}
              allInvestigationFields={allInvestigationFields}
              selectedInvestigationsFields={selectedInvestigationsFields}
              handleInvestigationFormsubmit={handleInvestigationFormsubmit}
              handleInvestigationInputChange={handleInvestigationInputChange}
              handleInvestigationValueChange={handleInvestigationValueChange}
            />
          ))}
    </div>
  );
};

export default Investigations;
