import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";

const CheckBox = ({ option, handleChange, selected }) => {
  const [isChecked, setIsChecked] = useState(
    selected.includes(option.name) ? true : false
  );

  const handleCheckboxChange = (e) => {
    handleChange(e, option.name);
    setIsChecked(!isChecked);
  };

  return (
    <Fragment>
      <label className="flex cursor-pointer select-none items-center">
        <div className="relative">
          <input
            type="checkbox"
            className="sr-only"
            name="complaints"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <div
            className={`mr-2 flex h-4 w-4 items-center justify-center rounded border ${
              isChecked && "border-meta-3 bg-white dark:bg-transparent"
            }`}
          >
            <span className={`opacity-0 ${isChecked && "!opacity-100"}`}>
              <CheckBadgeIcon className="w-4 h-4 text-meta-3" />
            </span>
          </div>
        </div>
        {option.label}
      </label>
    </Fragment>
  );
};

export default CheckBox;
