import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function TextAreaRich({ field, handleInputChange, formData }) {
  const handleQuillChange = (value) => {
    handleInputChange({
      target: {
        name: field.label,
        value: value,
      },
    });
  };

  return (
    <ReactQuill
      name={field.label}
      theme="snow"
      value={formData[field.label]}
      onChange={handleQuillChange}
    />
  );
}
