import React from "react";

const EmptyState = ({ entityName, handleEditClick }) => {
  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5 flex items-center justify-center">
            No {entityName} found {"   "}
            <span
              onClick={handleEditClick}
              className="text-meta-3 pl-3 cursor-pointer"
            >
              add {entityName}!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
