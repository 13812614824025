const normalAbormal = ['Normal', 'Abnormal'];

const fields = [
  {
    label: 'cardiovascularSystem',
    name: 'Cardiovascular System',
    type: 'select',
    options: normalAbormal,
    required: true,
  },
  {
    label: 'cardiovascularSystemAbnormal',
    name: 'Cardiovascular System',
    type: 'text',
    hide: true,
  },
  // {
  //   type: 'subTitle',
  //   name: 'Respiratory System',
  // },
  {
    label: 'respiratoryDistress',
    name: 'Respiratory System',
    type: 'select',
    options: normalAbormal,
    required: true,
  },
  {
    label: 'respiratoryDistressAbnormal',
    name: 'Respiratory System',
    type: 'text',
    hide: true,
  },
  // {
  //   type: 'subTitle',
  //   name: 'Gastrointestinal System',
  // },
  {
    label: 'ascites',
    name: 'Gastrointestinal System',
    type: 'select',
    options: normalAbormal,
    required: true,
  },
  {
    label: 'ascitesAbnormal',
    name: 'Gastrointestinal System',
    type: 'text',
    hide: true,
  },
  // {
  //   type: 'subTitle',
  //   name: 'Musculoskeletal system',
  // },
  {
    label: 'arthritis',
    name: 'Musculoskeletal system',
    type: 'select',
    options: normalAbormal,
    required: true,
  },
  {
    label: 'arthritisAbnormal',
    name: 'Musculoskeletal system',
    type: 'text',
    hide: true,
  },
  // {
  //   type: 'subTitle',
  //   name: 'Central nervous system',
  // },
  {
    label: 'dysarthria',
    name: 'Central nervous system',
    type: 'select',
    options: normalAbormal,
    required: true,
  },
  {
    label: 'dysarthriaAbnormal',
    name: 'Central nervous system',
    type: 'text',
    hide: true,
  },
];

const validationRules = {
  cardiovascularSystem: { required: true },
  respiratoryDistress: { required: true },
  ascites: { required: true },
  arthritis: { required: true },
  dysarthria: { required: true },
};

export { validationRules };
export default fields;
