import { useEffect, useState } from 'react';
import VitalsService from '../../../../../services/examinations/VitalsService';
import EmptyState from '../EmptyState';

const VitalsView = ({ admissionId, handleEditClick }) => {
  const [vitals, setVitals] = useState({});

  useEffect(() => {
    fetchVitals();
  }, []);

  const fetchVitals = async () => {
    try {
      const res = await VitalsService.getDetail(admissionId);
      setVitals(res);
    } catch (error) {
      console.error(error);
    }
  };

  if (!vitals.id) {
    return <EmptyState handleEditClick={handleEditClick} entityName="Vitals" />;
  }

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Fever', vitals.fever)}
              {renderField('Heart Rate', vitals.heartRate)}
              {renderField('Peripheral pulses', vitals.peripheralPulses)}
              {/* {vitals.peripheralPulses === "No" && renderField("Peripheral pulses No", vitals.peripheralPulsesNo)} */}
              {renderField('BP', vitals.bp)}
              {renderField('Tachypnea bradypnea', vitals.rr)}
              {renderField('CRT', vitals.crt)}
              {renderField('O2 Saturation', vitals.o2Satuaration)}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Vitals
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitalsView;
