import React from "react";
import DischargeService from "../../../../../services/admission/DischargeService";
import PanelTitle from "../../../../UI/PanelTitle";
import Discharge from "./Index";
import DischargeView from "./View";

const DischargeComponent = ({
  admission,
  editDischarge,
  setEditDischarge,
  handleCreate,
}) => {
  return (
    <div>
      <PanelTitle title="Discharge" />
      {editDischarge ? (
        <Discharge
          admission={admission}
          handleCancel={() => setEditDischarge(false)}
          handleVitalsCreate={(formData) =>
            handleCreate(
              formData,
              DischargeService,
              "Discharge info",
              setEditDischarge
            )
          }
        />
      ) : (
        <DischargeView
          admission={admission}
          handleEditClick={(e) => {
            e.preventDefault();
            setEditDischarge(true);
          }}
        />
      )}
    </div>
  );
};

export default DischargeComponent;
