const fields = [
  {
    label: 'assessment',
    name: 'Assessment',
    type: 'select',
    options: ['Normal', 'Abnormal'],
    required: true,
  },
  {
    label: 'assessmentAbnormal',
    name: 'Assessment',
    type: 'text',
    hide: true,
  },
];

const validationRules = {
  assessment: { required: true },
  assessmentAbnormal: {},
};

export { validationRules };
export default fields;
