const fields = [
  {
    name: 'Outcome',
    label: 'outcome',
    type: 'select',
    options: ['Cured', 'Improved', 'No Change', 'Expired'],
    required: true,
  },
  {
    name: 'Outcome Timing',
    label: 'outcomeTiming',
    type: 'select',
    options: ['Within 48 Hours', 'After 48 Hours'],
    required: false,
    hide: true,
  },
  {
    name: 'Referred to Other Hospitals',
    label: 'referredToOtherHospitals',
    type: 'select',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    name: 'Follow Up',
    label: 'followUp',
    type: 'text',
    required: false,
  },
  {
    name: 'Discharge Date',
    label: 'dateOfDischarge',
    type: 'date',
    required: true,
  },
  {
    name: 'Discharge Condition',
    label: 'dischargeCondition',
    type: 'text',
    required: false,
  },
  {
    name: 'Course During Hospital Stay',
    label: 'courseDuringHospitalStay',
    type: 'textarea-rich',
    required: false,
  },
  {
    name: 'Advice on Discharge',
    label: 'adviceOnDischarge',
    type: 'textarea-rich',
    required: false,
  },
];

const validationRules = {
  outcome: { required: true },
  outcomeTiming: {},
  deliveryCase: { required: true },
  referredToOtherHospitals: { required: true },
  courseDuringHospitalStay: {},
  adviceOnDischarge: {},
  followUp: {},
  dateOfDischarge: { required: true },
};

export { validationRules };
export default fields;
