import toast from "react-hot-toast";

const CREATED_MESSAGE = "created successfully.";
const UPDATED_MESSAGE = "saved successfully.";
const DELETED_MESSAGE = "deleted successfully.";

export function showSuccess(entityName) {
  return toast.success(`${entityName} ${CREATED_MESSAGE}`);
}

export function genericSuccess(message) {
  return toast.success(message);
}

export function showUpdated(entityName) {
  return toast.success(`${entityName} ${UPDATED_MESSAGE}`);
}

export function showDeleted(entityName) {
  return toast.success(`${entityName} ${DELETED_MESSAGE}`);
}

export function showError(message) {
  return toast.error(message);
}
