import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  showError,
  showSuccess,
  showUpdated,
} from '../../../../../data/messages';
import useServerError from '../../../../../hooks/useServerError';
import BirthDetailService from '../../../../../services/BirthDetailService';
import BirthHistoryService from '../../../../../services/BirthHistoryService';
import FamilyHistoryService from '../../../../../services/FamilyHistoryService';
import ImmunizationService from '../../../../../services/ImmunizationService';
import NutritionalService from '../../../../../services/NutritionalService';
import SocialHistoryService from '../../../../../services/SocialHistoryService';
import PanelTitle from '../../../../UI/PanelTitle';
import PatientDetailTabs from '../../../../UI/PatientDetailTabs/Index';
import BirthDetailCreate from '../../../Patient/Detail/BirthDetails/Create';
import BirthDetailView from '../../../Patient/Detail/BirthDetails/Detail';
import BirthHistoryCreate from '../../../Patient/Detail/BirthHistory/Create';
import BirthHistory from '../../../Patient/Detail/BirthHistory/Detail';
import FamilyHistoryCreate from '../../../Patient/Detail/FamilyHistory/Create';
import FamilyHistoryView from '../../../Patient/Detail/FamilyHistory/Detail';
import ImmunizationHistoryCreate from '../../../Patient/Detail/ImmunizationHistory/Create';
import ImmunisationHistoryView from '../../../Patient/Detail/ImmunizationHistory/Detail';
import NutritionalHistoryCreate from '../../../Patient/Detail/NutritionalHistory/Create';
import NutritionalHistoryView from '../../../Patient/Detail/NutritionalHistory/Detail';
import SocailHistoryCreate from '../../../Patient/Detail/SocialHistory/Create';
import SocialHistoryView from '../../../Patient/Detail/SocialHistory/Detail';

const PatientHistory = ({ admission }) => {
  const [editHistory, setEditHistory] = useState(false);
  const [editDetail, setEditDetail] = useState(false);
  const [editNutritional, setEditNutritional] = useState(false);
  const [editFamilyHistory, setEditFamilyHistory] = useState(false);
  const [editSocialHistory, setEditSocialHistory] = useState(false);
  const [editImmunizationHistory, setEditImmunizationHistory] = useState(false);
  const { updateServerError } = useServerError();

  const tabs = [
    {
      name: 'BIRTH/NEONATAL HISTORY',
      href: `#birth_history`,
      current: true,
    },
    {
      name: 'BIRTH DETAILS',
      href: `#birth_details`,
      current: false,
    },
    {
      name: 'NUTRITIONAL HISTORY',
      href: `#nutritional_history`,
      current: false,
    },
    {
      name: 'FAMILY HISTORY',
      href: `#family_history`,
      current: false,
    },
    {
      name: 'SOCIAL HISTORY',
      href: `#social_history`,
      current: false,
    },
    {
      name: 'IMMUNISATION HISTORY',
      href: `#immunisation_history`,
      current: false,
    },
  ];
  const [updatedTabs, setUpdatedTabs] = useState(tabs);

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash || '#birth_history';
    const newTabs = tabs.map((tab) => ({
      ...tab,
      current: tab.href === hash,
    }));

    setUpdatedTabs(newTabs);
  }, [location.hash]);

  const handleCreate = async (formData, service, entityName, setEditState) => {
    try {
      if (formData.patientId && formData.id) {
        await service.update(admission.id, parseInt(formData.id), formData);
        showUpdated(entityName);
      } else {
        await service.create(admission.id, formData);
        showSuccess(entityName);
      }

      setEditState(false);
    } catch (error) {
      updateServerError(error, showError);
    }
  };

  const handleBirthDetailSave = async (formData) => {
    await handleCreate(
      formData,
      BirthDetailService,
      'Birth detail',
      setEditDetail,
    );
  };

  const handleBirthHistorySave = async (formData) => {
    await handleCreate(
      formData,
      BirthHistoryService,
      'Birth history',
      setEditHistory,
    );
  };

  const handleNutritionalHistorySave = async (formData) => {
    await handleCreate(
      formData,
      NutritionalService,
      'Nutritional history',
      setEditNutritional,
    );
  };

  const handleFamilyHistorySave = async (formData) => {
    await handleCreate(
      formData,
      FamilyHistoryService,
      'Family history',
      setEditFamilyHistory,
    );
  };

  const handleSocialHistorySave = async (formData) => {
    await handleCreate(
      formData,
      SocialHistoryService,
      'Social history',
      setEditSocialHistory,
    );
  };

  const handleImmunisationHistorySave = async (formData) => {
    await handleCreate(
      formData,
      ImmunizationService,
      'Immunization history',
      setEditImmunizationHistory,
    );
  };

  return (
    <div>
      <PanelTitle title="Patient History" />
      <PatientDetailTabs updatedTabs={updatedTabs} />
      {(location.hash === '#birth_history' || location.hash === '') && (
        <>
          {' '}
          {editHistory ? (
            <BirthHistoryCreate
              patientId={admission.id}
              handleCancel={() => setEditHistory(false)}
              handleBirthHistorySave={handleBirthHistorySave}
            />
          ) : (
            <BirthHistory
              patientId={admission.id}
              handleEditClick={(event) => {
                event.preventDefault();
                setEditHistory(true);
              }}
            />
          )}
        </>
      )}
      {location.hash === '#birth_details' && (
        <>
          {' '}
          {editDetail ? (
            <BirthDetailCreate
              patientId={admission.id}
              handleCancel={() => setEditDetail(false)}
              handleBirthDetailSave={handleBirthDetailSave}
            />
          ) : (
            <BirthDetailView
              patientId={admission.id}
              handleEditClick={(event) => {
                event.preventDefault();
                setEditDetail(true);
              }}
            />
          )}
        </>
      )}
      {location.hash === '#nutritional_history' && (
        <>
          {' '}
          {editNutritional ? (
            <NutritionalHistoryCreate
              patientId={admission.id}
              handleCancel={() => setEditNutritional(false)}
              handleNutritionalHistorySave={handleNutritionalHistorySave}
            />
          ) : (
            <NutritionalHistoryView
              patientId={admission.id}
              handleEditClick={(e) => {
                e.preventDefault();
                setEditNutritional(true);
              }}
            />
          )}
        </>
      )}
      {location.hash === '#family_history' && (
        <>
          {' '}
          {editFamilyHistory ? (
            <FamilyHistoryCreate
              patientId={admission.id}
              handleCancel={() => setEditFamilyHistory(false)}
              handleFamilyHistorySave={handleFamilyHistorySave}
            />
          ) : (
            <FamilyHistoryView
              patientId={admission.id}
              handleEditClick={(e) => {
                e.preventDefault();
                setEditFamilyHistory(true);
              }}
            />
          )}
        </>
      )}
      {location.hash === '#social_history' && (
        <>
          {' '}
          {editSocialHistory ? (
            <SocailHistoryCreate
              patientId={admission.id}
              handleCancel={() => setEditSocialHistory(false)}
              handleSocialHistorySave={handleSocialHistorySave}
            />
          ) : (
            <SocialHistoryView
              patientId={admission.id}
              handleEditClick={(e) => {
                e.preventDefault();
                setEditSocialHistory(true);
              }}
            />
          )}
        </>
      )}
      {location.hash === '#immunisation_history' && (
        <>
          {' '}
          {editImmunizationHistory ? (
            <ImmunizationHistoryCreate
              patientId={admission.id}
              handleCancel={() => setEditImmunizationHistory(false)}
              handleImmunisationHistorySave={handleImmunisationHistorySave}
            />
          ) : (
            <ImmunisationHistoryView
              patientId={admission.id}
              handleEditClick={(e) => {
                e.preventDefault();
                setEditImmunizationHistory(true);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PatientHistory;
