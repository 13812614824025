const renderValue = (name, value) => {
  return (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{name}</label>
      <p>{value}</p>
    </div>
  );
};

const renderComplaintsValue = (name, complaints) => {
  return (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{name}</label>
      <div>
        {Array.isArray(complaints) &&
          complaints.length > 0 &&
          complaints.map((otherItem, index) => (
            <p
              key={index}
              className="inline-flex rounded-full bg-white bg-opacity-10 py-1 px-3 text-sm font-medium text-danger mr-2"
            >
              {otherItem}
            </p>
          ))}
      </div>
    </div>
  );
};
const renderChiefComplaintsValue = (name, complaints) => {
  return (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{name}</label>
      <div>
        {Array.isArray(complaints) &&
          complaints.length > 0 &&
          complaints.map((otherItem, index) => (
            <p
              key={index}
              className="inline-flex rounded-full bg-white bg-opacity-10 py-1 px-3 text-sm font-medium text-danger mr-2"
            >
              {otherItem.complaint} : {otherItem.durationValue}{" "}
              {otherItem.durationUnit}
            </p>
          ))}
      </div>
    </div>
  );
};

export { renderChiefComplaintsValue, renderComplaintsValue, renderValue };
