const initialFields = {
  consanguinity: 'No',
  medicalHistory: 'No',
  historyValue: '',
  similarIllnessInFamilyMember: 'No',
  whatIllness: '',
  typeOfFamily: 'Nuclear',
  historyOfCommunicableDiseaseContact: '',
};

export default initialFields;
