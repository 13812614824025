import React from "react";
import AnthropometryService from "../../../../../services/examinations/AnthropometryService";
import Anthropometry from "./Index";
import AnthropometryView from "./View";

const AnthropometryComponent = ({
  admissionId,
  editAnthropometry,
  setEditAnthropometry,
  handleCreate,
}) => {
  return (
    <>
      {editAnthropometry ? (
        <Anthropometry
          admissionId={admissionId}
          handleCancel={() => setEditAnthropometry(false)}
          handleCreate={(formData) =>
            handleCreate(
              formData,
              AnthropometryService,
              "Anthropometry",
              setEditAnthropometry
            )
          }
        />
      ) : (
        <AnthropometryView
          admissionId={admissionId}
          handleEditClick={(e) => {
            e.preventDefault();
            setEditAnthropometry(true);
          }}
        />
      )}
    </>
  );
};

export default AnthropometryComponent;
