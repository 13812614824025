const fields = [
  {
    label: 'underweight',
    name: 'Underweight',
    type: 'select',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    label: 'wasted',
    name: 'Wasted',
    type: 'select',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    label: 'stunted',
    name: 'Stunted',
    type: 'select',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    label: 'bsa',
    name: 'BMI (kg/m2)',
    type: 'text',
  },
  {
    label: 'smrStage',
    name: 'SMR Stage(10-19 yrs)',
    type: 'text',
  },
  {
    label: 'ofc',
    name: 'OFC in cm (Upto 5 years)',
    type: 'text',
  },
];

const validationRules = {
  underweight: { required: true },
  wasted: { required: true },
  stunted: { required: true },
  bsa: {},
  smrStage: { required: true },
};

export { validationRules };
export default fields;
