import React from 'react';
import { useLocation } from 'react-router-dom';
import PanelTitle from '../../../UI/PanelTitle';
import PatientDetailTabs from '../../../UI/PatientDetailTabs/Index';
import AnthropometryComponent from './Anthropometry/AnthropometryComponent';
import DevelopmentalComponent from './Developmental/DevelopmentalComponent';
import HeadToToeComponent from './HeadToToeExamination/HeadToToeComponent';
import SystemicComponent from './Systemic/SystemicComponent';
import VitalsComponent from './Vitals/VitalsComponent';

const Examinations = ({
  patientId,
  admission,
  editVitals,
  setEditVitals,
  editHeadToToe,
  setEditHeadToToe,
  editAnthropometry,
  setEditAnthropometry,
  editSystemic,
  setEditSystemic,
  handleCreate,
  editDevelopmental,
  setEditDevelopmental,
}) => {
  const location = useLocation();

  const currentPath = location.pathname;
  const tabs = [
    {
      name: 'Vitals',
      href: `/u/patients/${patientId}/admissions/${admission.id}/vitals`,
      current: true,
    },
    {
      name: 'Head to Toe',
      href: `/u/patients/${patientId}/admissions/${admission.id}/head_to_toe`,
      current: false,
    },
    {
      name: 'Anthropometry',
      href: `/u/patients/${patientId}/admissions/${admission.id}/anthropometry`,
      current: false,
    },
    {
      name: 'Systemic',
      href: `/u/patients/${patientId}/admissions/${admission.id}/systemic`,
      current: false,
    },
    {
      name: 'Developmental',
      href: `/u/patients/${patientId}/admissions/${admission.id}/developmental`,
      current: false,
    },
  ];

  const updatedTabs = tabs.map((tab) => ({
    ...tab,
    current: tab.href === location.pathname,
  }));

  return (
    <div>
      <PanelTitle title="Examinations" />
      <PatientDetailTabs updatedTabs={updatedTabs} />

      {currentPath.startsWith(
        `/u/patients/${patientId}/admissions/${admission.id}/vitals`,
      ) && (
        <VitalsComponent
          admissionId={admission.id}
          editVitals={editVitals}
          setEditVitals={setEditVitals}
          handleCreate={handleCreate}
        />
      )}

      {currentPath.startsWith(
        `/u/patients/${patientId}/admissions/${admission.id}/head_to_toe`,
      ) && (
        <HeadToToeComponent
          admissionId={admission.id}
          editHeadToToe={editHeadToToe}
          setEditHeadToToe={setEditHeadToToe}
          handleCreate={handleCreate}
        />
      )}

      {currentPath.startsWith(
        `/u/patients/${patientId}/admissions/${admission.id}/anthropometry`,
      ) && (
        <AnthropometryComponent
          admissionId={admission.id}
          editAnthropometry={editAnthropometry}
          setEditAnthropometry={setEditAnthropometry}
          handleCreate={handleCreate}
        />
      )}

      {currentPath.startsWith(
        `/u/patients/${patientId}/admissions/${admission.id}/systemic`,
      ) && (
        <SystemicComponent
          admissionId={admission.id}
          editSystemic={editSystemic}
          setEditSystemic={setEditSystemic}
          handleCreate={handleCreate}
        />
      )}
      {currentPath.startsWith(
        `/u/patients/${patientId}/admissions/${admission.id}/developmental`,
      ) && (
        <DevelopmentalComponent
          admissionId={admission.id}
          editDevelopmental={editDevelopmental}
          setEditDevelopmental={setEditDevelopmental}
          handleCreate={handleCreate}
        />
      )}
    </div>
  );
};

export default Examinations;
