import { CHILDCARE_USER_TOKEN } from "../data/constants";

const loadState = (key = CHILDCARE_USER_TOKEN) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const saveState = (key = CHILDCARE_USER_TOKEN, state, cb = null) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
    if (cb) {
      cb();
    }
  } catch (error) {
    console.error(error);
  }
};

const removeState = (key = CHILDCARE_USER_TOKEN) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};

export { loadState, removeState, saveState };
