import { useState } from "react";
import { validateForm } from "../../../../../validation/formValidation";
import PatientForm from "../../../Patient/Form/Index";
import ChiefComplaints from "./ChiefComplaints";

const GeneralAdmission = ({
  patientId,
  createAdmission,
  initialFields,
  fields,
  validationRules,
}) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [admissionFields, setAdmissionFields] = useState(fields);
  const [chiefComplaints, setChiefComplaints] = useState(
    initialFields.chiefComplaints || [
      {
        complaint: "",
        durationValue: "",
        durationUnit: "hour",
      },
    ]
  );

  const addComplaint = (e) => {
    e.preventDefault();

    const newComplaint = {
      complaint: "",
      durationValue: "",
      durationUnit: "hour",
    };

    setChiefComplaints((prevComplaints) => [...prevComplaints, newComplaint]);
  };

  const handleChange = (index, field, value) => {
    setChiefComplaints((prevComplaints) => {
      const updatedComplaints = [...prevComplaints];
      updatedComplaints[index] = {
        ...updatedComplaints[index],
        [field]: value,
      };
      return updatedComplaints;
    });
  };

  const handleDelete = (index) => {
    setChiefComplaints((prevComplaints) => {
      const updatedComplaints = [...prevComplaints];
      updatedComplaints.splice(index, 1);
      return updatedComplaints;
    });
  };

  const handleInputChange = (e, extra = null) => {
    const { name, value } = e.target;

    if (e.target.type === "checkbox") {
      const label = extra;
      const isChecked = e.target.checked;

      if (isChecked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          complaints: [...prevFormData.complaints, label],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          complaints: prevFormData.complaints.filter(
            (complaint) => complaint !== label
          ),
        }));
      }
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const hasEmptyComplaintOrDuration = chiefComplaints.some(
      (complaint) =>
        complaint.complaint.trim() === "" ||
        complaint.durationValue.trim() === ""
    );

    if (hasEmptyComplaintOrDuration) {
      return;
    }

    const errors = validateForm(formData, admissionFields, validationRules);

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      formData.chiefComplaints = chiefComplaints;
      createAdmission(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="pl-3 pr-3 pb-5 bg-white">
      <ChiefComplaints
        chiefComplaints={chiefComplaints}
        addComplaint={addComplaint}
        handleChange={handleChange}
        handleDelete={handleDelete}
      />

      <PatientForm
        formTitle="Admission"
        backLink={`/u/patients/${patientId}/birth_history`}
        formFields={admissionFields}
        formErrors={formErrors}
        formData={formData}
        handleInputChange={handleInputChange}
      />
    </form>
  );
};

export default GeneralAdmission;
