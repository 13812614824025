import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { imagableInvestigations } from "../../../../../data/investigations";
import { showDeleted, showError } from "../../../../../data/messages";
import useServerError from "../../../../../hooks/useServerError";
import InvestigationService from "../../../../../services/admission/InvestigationService";
import Layout from "../Layout";
import InvestigationOptions from "./InvestigationOptions";

const InvestigationForm = ({
  admission,
  investigation,
  allInvestigationFields,
  selectedInvestigationsFields,
  handleInvestigationFormsubmit,
  handleInvestigationInputChange,
  handleInvestigationValueChange,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [investigationImages, setInvestigationImages] = useState([]);
  const { updateServerError } = useServerError();

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const handleImageReset = () => {
    setTimeout(() => {
      setSelectedFiles(null);
      if (imagableInvestigations.includes(investigation.name)) {
        fetchInvestigationImages(investigation, admission);
      }
    }, 3000);
  };

  useEffect(() => {
    if (imagableInvestigations.includes(investigation.name)) {
      fetchInvestigationImages(investigation, admission);
    }
  }, []);

  const fetchInvestigationImages = async (investigation, admission) => {
    try {
      const res = await InvestigationService.fetchImages(
        admission.id,
        investigation.name
      );
      setInvestigationImages(res);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteImage = async (event, imageId) => {
    event.preventDefault();

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (confirmDelete) {
      try {
        await InvestigationService.deleteImage(imageId);
        showDeleted("Image");
        setTimeout(() => {
          fetchInvestigationImages(investigation, admission);
        }, 2000);
      } catch (error) {
        updateServerError(error, showError);
        console.error(error);
      }
    }
  };

  const previewImages = () => {
    if (!selectedFiles) {
      return null;
    }

    const handleRemoveFile = (event, index) => {
      event.preventDefault();

      setSelectedFiles((prevSelectedFiles) => {
        const updatedFiles = [...prevSelectedFiles];
        updatedFiles.splice(index, 1);
        return updatedFiles;
      });
    };

    return (
      <div className="mt-4 grid grid-cols-4 gap-4">
        {selectedFiles &&
          Array.from(selectedFiles).map((file, index) => (
            <div
              key={index}
              className="relative w-full h-48 rounded-md overflow-hidden"
            >
              {file.size > 1 * 1024 * 1024 ? (
                <div className="w-full h-full flex flex-col items-center justify-center bg-gray-200 text-red-500 font-bold">
                  <div>File size exceeds 1MB</div>
                  <button
                    className="mt-2 p-2 rounded-full bg-white shadow-md hover:bg-red-500 hover:text-white focus:outline-none"
                    onClick={(event) => handleRemoveFile(event, index)}
                  >
                    Remove
                  </button>
                </div>
              ) : (
                <>
                  <img
                    className="w-full h-full object-cover"
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                  />
                  <button
                    className="absolute top-0 right-0 p-2 rounded-full bg-white shadow-md hover:bg-red-500 hover:text-white focus:outline-none"
                    onClick={(event) => handleRemoveFile(event, index)}
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </button>
                </>
              )}
            </div>
          ))}
      </div>
    );
  };

  return (
    <Layout
      title={`${investigation.label}`}
      initialOpen={false}
      key={investigation.id}
    >
      <div className="flex flex-col p-3">
        <form
          onSubmit={(e) =>
            handleInvestigationFormsubmit(
              e,
              investigation.name,
              selectedFiles,
              handleImageReset
            )
          }
        >
          <div className="grid grid-cols-4 gap-4">
            <div className="grid grid-cols-3 gap-4 col-span-2">
              <InvestigationOptions
                options={allInvestigationFields[investigation.name]}
                selectedInvestigationsFields={selectedInvestigationsFields}
                handleInvestigationInputChange={handleInvestigationInputChange}
                investigationName={investigation.name}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 col-span-2">
              {!imagableInvestigations.includes(investigation.name) &&
                allInvestigationFields[investigation.name]
                  ?.filter((field) => !field.hide)
                  .map((field) => (
                    <div key={field.name} className="mb-2 w-full">
                      <label
                        htmlFor={field.label}
                        className="text-sm font-medium text-gray-700 block mb-1"
                      >
                        {field.label}{" "}
                        {field?.unit && (
                          <span className="text-xs text-meta-3">
                            {field.unit}
                          </span>
                        )}
                      </label>
                      <input
                        name={field.name}
                        type="text"
                        value={field.value}
                        onChange={(e) =>
                          handleInvestigationValueChange(
                            e,
                            field.name,
                            investigation.name
                          )
                        }
                        className="w-full rounded border-[1px] border-stroke bg-transparent py-1 px-2 font-xs outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3"
                      />
                      <div className="flex items-center mt-1">
                        <span className="text-xs text-gray-500 ml-2">
                          {field.extraText}
                        </span>
                      </div>
                    </div>
                  ))}

                  {imagableInvestigations.includes(investigation.name) && allInvestigationFields[investigation.name]
                    ?.filter((field) => !field.hide && field.specialType)
                    .map((field) => (
                    <div key={field.name} className="mb-2 w-full">
                      <label
                        htmlFor={field.label}
                        className="text-sm font-medium text-gray-700 block mb-1"
                      >
                        {field.label}{" "}
                        {field?.unit && (
                          <span className="text-xs text-meta-3">
                            {field.unit}
                          </span>
                        )}
                      </label>
                      <input
                        name={field.name}
                        type="text"
                        value={field.value}
                        onChange={(e) =>
                          handleInvestigationValueChange(
                            e,
                            field.name,
                            investigation.name
                          )
                        }
                        className="w-full rounded border-[1px] border-stroke bg-transparent py-1 px-2 font-xs outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3"
                      />
                      <div className="flex items-center mt-1">
                        <span className="text-xs text-gray-500 ml-2">
                          {field.extraText}
                        </span>
                      </div>
                    </div>
                  ))}

              {imagableInvestigations.includes(investigation.name) && (
                <div className="col-span-full">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Add Images
                  </label>
                  <div className="mt-2 mb-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                      <PhotoIcon
                        className="mx-auto h-12 w-12 text-gray-300"
                        aria-hidden="true"
                      />
                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="images"
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload a file</span>
                          <input
                            id="images"
                            name="images"
                            type="file"
                            className="sr-only"
                            multiple
                            onChange={handleFileSelect}
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {previewImages()}
          {imagableInvestigations.includes(investigation.name) &&
            investigationImages.length > 0 && (
              <div className="mt-4 grid grid-cols-4 gap-4">
                {investigationImages.map((image) => (
                  <div
                    key={image.id}
                    className="relative w-full h-48 rounded-md overflow-hidden"
                  >
                    <>
                      <img
                        className="w-full h-full object-cover"
                        src={image.imageUrl}
                        alt={"Investigation"}
                      />
                      <button
                        className="absolute top-0 right-0 p-2 rounded-full bg-white shadow-md hover:bg-red-500 hover:text-white focus:outline-none"
                        onClick={(event) => handleDeleteImage(event, image.id)}
                      >
                        <XMarkIcon className="w-4 h-4" />
                      </button>
                    </>
                  </div>
                ))}
              </div>
            )}
          <div className="flex justify-end items-end">
            <button
              type="submit"
              className="rounded bg-meta-3 p-3 font-medium text-white"
            >
              Save {investigation.label}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default InvestigationForm;
