// formValidation.js
export const validateField = (fieldLabel, value, validationRules) => {
  let errors = [];

  if (validationRules.required && !value) {
    errors.push(`${fieldLabel} is required.`);
  }

  if (validationRules.isArray && !Array.isArray(value)) {
    errors.push(`${fieldLabel} must be an array.`);
  } else if (
    validationRules.isArray &&
    validationRules.min &&
    Array.isArray(value) &&
    value.length < validationRules.min
  ) {
    errors.push(
      `${fieldLabel} must have at least ${validationRules.min} item(s).`
    );
  }

  if (
    validationRules.maxLength &&
    value &&
    value.length > validationRules.maxLength
  ) {
    errors.push(
      `${fieldLabel} must be ${validationRules.maxLength} characters or less.`
    );
  }

  if (
    validationRules.minValue &&
    value &&
    parseInt(value) < validationRules.minValue
  ) {
    errors.push(`${fieldLabel} must be at least ${validationRules.minValue}.`);
  }

  if (
    validationRules.maxValue &&
    value &&
    parseInt(value) > validationRules.maxValue
  ) {
    errors.push(`${fieldLabel} must be at most ${validationRules.maxValue}.`);
  }

  if (validationRules.isNumeric && value && isNaN(Number(value))) {
    errors.push(`${fieldLabel} must be a numeric value.`);
  }

  return errors;
};

export const validateForm = (formData, patientFields, validationRules = {}) => {
  if (Object.keys(validationRules).length === 0) {
    return {}; // Skip validation if validationRules is empty
  }

  let errors = {};
  const requiredFields = patientFields.filter((field) => field.required);

  requiredFields.forEach((field) => {
    const fieldLabel = field.name;
    const fieldValue = formData[field.label];
    const fieldValidationRules = validationRules[field.label];

    const fieldErrors = validateField(
      fieldLabel,
      fieldValue,
      fieldValidationRules
    );
    if (fieldErrors.length > 0) {
      errors[field.label] = fieldErrors;
    }
  });

  return errors;
};
