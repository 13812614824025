const symptoms = [
  "Fever",
  "cold",
  "cough",
  "ear discharge",
  "earache",
  "throat pain",
  "difficulty swallowing",
  "SOB",
  "palpitation",
  "bluish discoloration",
  "LOC",
  "seizure",
  "weakness of limb/body",
  "headache",
  "difficulty in walking",
  "vomiting",
  "loose motion",
  "pain abdomen",
  "rash",
  "red urine",
  "oliguria",
  "polyuria",
  "polydipsia",
  "frothiness of urine",
  "Dysuria",
  "joint pain",
  "swelling of joint",
  "hematemesis",
  "malena",
  "epistaxis",
  "bleeding from other sites",
  "generalized swelling of the body",
  "palenes",
];

const seizureYes = [
  "generalized",
  "focal",
  "weakness",
  "of limb",
  "body",
  "headache",
  "difficulty in walking",
  "vomiting",
  "loose motion",
  "pain abdomen",
  "rash",
  "red urine",
  "oliguria",
  "polyuria",
  "polydipsia",
  "frothiness of urine",
  "Dysuria",
  "joint pain",
  "swelling of joint",
  "hematemesis",
  "malena",
  "epistaxis",
  "bleeding from other sites",
  "generalized swelling of the body",
  "palenes",
];

export default symptoms;
export { seizureYes };
