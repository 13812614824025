import React from "react";
import DevelopmentalService from "../../../../../services/examinations/DevelopmentalService";
import Developmental from "./Index";
import DevelopmentalView from "./View";

const DevelopmentalComponent = ({
  admissionId,
  editDevelopmental,
  setEditDevelopmental,
  handleCreate,
}) => {
  return (
    <>
      {editDevelopmental ? (
        <Developmental
          admissionId={admissionId}
          handleCancel={() => setEditDevelopmental(false)}
          handleCreate={(formData) =>
            handleCreate(
              formData,
              DevelopmentalService,
              "Developmental",
              setEditDevelopmental
            )
          }
        />
      ) : (
        <DevelopmentalView
          admissionId={admissionId}
          handleEditClick={(e) => {
            e.preventDefault();
            setEditDevelopmental(true);
          }}
        />
      )}
    </>
  );
};

export default DevelopmentalComponent;
