const fields = [
  {
    name: 'Feeding Type (<=6 months )',
    label: 'breastFeeding',
    type: 'select',
    options: ['Exclusive BF', 'Mixed', 'Only formula'],
    required: true,
  },
  // {
  //   name: 'Calories per day',
  //   label: 'caloriesPerDay',
  //   type: 'text',
  //   required: true,
  // },
];

let validationRules = {
  breastFeeding: { required: true },
  caloriesPerDay: { required: true },
};

export default fields;
export { validationRules };
