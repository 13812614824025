import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../../data/constants";
import { VITALS_DETAIL, VITALS_UPDATE } from "../../data/constants/api";
import { loadState } from "../../utils/localStorage";
import Api from "../Api";

class VitalsService {
  async getDetail(admissionId) {
    const res = await Api.get(
      template(VITALS_DETAIL)({ admissionId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(admissionId, payload) {
    const res = await Api.post(
      template(VITALS_DETAIL)({ admissionId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async update(admissionId, examVitalId, payload) {
    const res = await Api.patch(
      template(VITALS_UPDATE)({ admissionId, examVitalId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new VitalsService();
