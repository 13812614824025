import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../data/constants";
import {
  NUTRITIONAL_DETAIL,
  NUTRITIONAL_HISTORY_UPDATE,
} from "../data/constants/api";
import { loadState } from "../utils/localStorage";
import Api from "./Api";

class NutritionalService {
  async getDetail(patientId) {
    const res = await Api.get(
      template(NUTRITIONAL_DETAIL)({ patientId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(patientId, payload) {
    const res = await Api.post(
      template(NUTRITIONAL_DETAIL)({ patientId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async update(patientId, nutritionalHistoryId, payload) {
    const res = await Api.patch(
      template(NUTRITIONAL_HISTORY_UPDATE)({ patientId, nutritionalHistoryId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new NutritionalService();
