import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import CheckBox from "../../../UI/CheckBox/Index";
import DateField from "../../../UI/Form/DateField";
import TextAreaRich from "../../../UI/Form/TextAreaRich";
import Multiselect from "../../../UI/MultiSelect/Index";

const Index = ({
  formTitle,
  backLink,
  formFields,
  formErrors,
  formData,
  handleInputChange,
  cancelButton,
  handleCancelButtonClick,
}) => {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
        {formFields
          .filter((field) => !field.hide)
          .map((field, index) => (
            <div
              key={index}
              className={
                `flex flex-col flex-grow-0` +
                (field.type === "textarea" ||
                field.type === "checkbox" ||
                field.type === "subTitle" ||
                field.type === "textarea-rich"
                  ? " col-span-4"
                  : "")
              }
            >
              <label className="mb-2.5 block text-black dark:text-white">
                {field.name}{" "}
                {field.required && <span className="text-meta-1">*</span>}
              </label>
              {field.type === "select" ? (
                <Fragment>
                  <select
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3"
                    name={field.label}
                    value={formData[field.label]}
                    onChange={handleInputChange}
                  >
                    {field.options.map((option, optionIndex) => (
                      <option key={optionIndex} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {formErrors[field.label] && (
                    <p className="text-danger mt-1 text-sm">
                      {formErrors[field.label]}
                    </p>
                  )}
                </Fragment>
              ) : field.type === "multi-select" ? (
                <Fragment>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      type="text"
                      name={field.labelValue}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3"
                      value={formData[field.labelValue]}
                      onChange={handleInputChange}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center">
                      <label htmlFor="age" className="sr-only">
                        {field.label}
                      </label>
                      <select
                        className="w-full rounded-md border-0 bg-transparent py-0 pl-2 pr-1 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                        value={formData[field.labelUnit]}
                        name={field.labelUnit}
                        onChange={handleInputChange}
                      >
                        {field.optionUnits.map((option, optionIndex) => (
                          <option key={optionIndex} value={option.name}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {formErrors[field.label] && (
                    <p className="text-danger mt-1 text-sm">
                      {formErrors[field.label]}
                    </p>
                  )}
                </Fragment>
              ) : field.type === "multiple-select" ? (
                <Fragment>
                  <Multiselect
                    items={field.options}
                    name={field.label}
                    defaultFields={formData[field.label]}
                    handleChange={handleInputChange}
                  />
                  {formErrors[field.label] && (
                    <p className="text-danger mt-1 text-sm">
                      {formErrors[field.label]}
                    </p>
                  )}
                </Fragment>
              ) : field.type === "textarea" ? (
                <Fragment>
                  <textarea
                    name={field.label}
                    rows={2} // You can adjust the number of rows as needed
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3"
                    value={formData[field.label]}
                    onChange={handleInputChange}
                  />
                  {formErrors[field.label] && (
                    <p className="text-danger mt-1 text-sm">
                      {formErrors[field.label]}
                    </p>
                  )}
                </Fragment>
              ) : field.type === "textarea-rich" ? (
                <div className="mb-1">
                  <TextAreaRich
                    field={field}
                    handleInputChange={handleInputChange}
                    formData={formData}
                  />
                  {formErrors[field.label] && (
                    <p className="text-danger mt-1 text-sm">
                      {formErrors[field.label]}
                    </p>
                  )}
                </div>
              ) : field.type === "checkbox" ? (
                <Fragment>
                  <div className="grid grid-cols-4 gap-4">
                    {field.options.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <CheckBox
                          option={{ name: option, label: option }}
                          selected={formData[field.label]}
                          handleChange={handleInputChange}
                        />
                      </div>
                    ))}
                  </div>
                  {formErrors[field.label] && (
                    <p className="text-danger mt-1 text-sm">
                      {formErrors[field.label]}
                    </p>
                  )}
                </Fragment>
              ) : field.type === "subTitle" ? (
                <p className="w-full rounded border-[1px] border-stroke bg-transparent font-medium outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3"></p>
              ) : field.type === "date" ? (
                <DateField
                  field={field}
                  handleInputChange={handleInputChange}
                  formData={formData}
                  formErrors={formErrors}
                />
              ) : (
                <Fragment>
                  <input
                    type={field.type}
                    name={field.label}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3"
                    value={formData[field.label]}
                    onChange={handleInputChange}
                  />
                  {formErrors[field.label] && (
                    <p className="text-danger mt-1 text-sm">
                      {formErrors[field.label]}
                    </p>
                  )}
                </Fragment>
              )}
            </div>
          ))}
      </div>
      <div className="grid grid-cols-1 gap-9 p-3">
        <div className="flex justify-end">
          {backLink && (
            <Link to={backLink}>
              <button
                type="button"
                className="rounded bg-danger p-3 font-medium text-white ml-3"
              >
                Back
              </button>
            </Link>
          )}

          {cancelButton && (
            <button
              type="button"
              className="rounded bg-danger p-3 font-medium text-white ml-3"
              onClick={handleCancelButtonClick}
            >
              Cancel
            </button>
          )}

          <button
            type="submit"
            className="rounded bg-meta-3 p-3 ml-3 font-medium text-white"
          >
            Save {formTitle}
          </button>
        </div>
      </div>
    </>
  );
};

export default Index;
