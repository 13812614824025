import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/solid";

const ChiefComplaints = ({
  chiefComplaints,
  handleChange,
  addComplaint,
  handleDelete,
}) => {
  return (
    <div className="p-3">
      <h3 className="pt-1 pb-5">Chief Complaints</h3>
      {chiefComplaints?.length &&
        chiefComplaints?.map((complaint, index) => (
          <div key={index}>
            <div
              className={
                index === 0
                  ? `mb-4.5 flex flex-col gap-6 xl:flex-row`
                  : `flex flex-col gap-6 xl:flex-row`
              }
            >
              <div className="w-full xl:w-1/2">
                <label className="mb-2.5 block text-black dark:text-white">
                  Complaint {index + 1}
                </label>
                <input
                  type="text"
                  placeholder="Enter complaint"
                  className={`w-full rounded border-[1.5px] bg-transparent py-3 px-5 font-medium outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3 ${
                    !complaint.complaint && "border-danger" // Add red border for validation
                  }`}
                  value={complaint.complaint}
                  onChange={(e) =>
                    handleChange(index, "complaint", e.target.value)
                  }
                />
                {!complaint.complaint && (
                  <span className="text-danger text-xs mt-1">
                    Please enter a complaint
                  </span>
                )}
              </div>
              <div className="w-full xl:w-1/2 ">
                <label className="mb-2.5 block text-black dark:text-white">
                  Duration
                </label>
                <div className="flex items-center">
                  <input
                    type="number"
                    placeholder="Enter Duration Value"
                    className={`w-3/4 rounded-left border-[1.5px] bg-transparent py-3 px-5 font-medium outline-none transition focus:border-meta-3 active:border-meta-3 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3 ${
                      !complaint.durationValue && "border-danger" // Add red border for validation
                    }`}
                    value={complaint.durationValue}
                    onChange={(e) =>
                      handleChange(index, "durationValue", e.target.value)
                    }
                  />
                  <select
                    className={`w-1/4 relative z-20 appearance-none border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-meta-3 active:border-meta-3 dark:border-form-strokedark dark:bg-form-input dark:focus:border-meta-3 ${
                      !complaint.durationUnit && "border-red-500" // Add red border for validation
                    }`}
                    value={complaint.durationUnit}
                    onChange={(e) =>
                      handleChange(index, "durationUnit", e.target.value)
                    }
                  >
                    <option value="hour">Hour(s)</option>
                    <option value="day">Day(s)</option>
                    <option value="week">Week(s)</option>
                    <option value="month">Month(s)</option>
                  </select>
                  <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                    <ChevronDownIcon className="w-4 h-4 inline" />
                  </span>
                </div>
                {!complaint.durationValue && (
                  <span className="text-danger text-xs mt-1">
                    Please enter a duration value
                  </span>
                )}
                {!complaint.durationUnit && (
                  <span className="text-danger text-xs mt-1">
                    Please select a duration unit
                  </span>
                )}
              </div>
            </div>
            {index !== 0 && (
              <span
                className="mb-4.5 text-danger text-xs cursor-pointer"
                onClick={() => handleDelete(index)}
              >
                Remove
              </span>
            )}
          </div>
        ))}

      {chiefComplaints.length < 3 && (
        <div className="flex justify-between items-center">
          <button
            onClick={(e) => addComplaint(e)}
            className="flex-shrink-0 rounded bg-meta-3 p-2 font-medium text-gray"
          >
            Add Complaint <PlusIcon className="w-4 h-4 inline" />
          </button>
        </div>
      )}

      <div className="border-t border-meta-4 w-full mt-3"></div>
    </div>
  );
};

export default ChiefComplaints;
