import { template } from "lodash/fp";
import { CHILDCARE_USER_TOKEN } from "../data/constants";
import {
  BIRTH_DETAILS_DETAIL,
  BIRTH_DETAILS_UPDATE,
} from "../data/constants/api";
import { loadState } from "../utils/localStorage";
import Api from "./Api";

class BirthDetailService {
  async getDetail(patientId) {
    const res = await Api.get(
      template(BIRTH_DETAILS_DETAIL)({ patientId }),
      {},
      false,
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async create(patientId, payload) {
    const res = await Api.post(
      template(BIRTH_DETAILS_DETAIL)({ patientId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }

  async update(patientId, birthDetailsId, payload) {
    const res = await Api.patch(
      template(BIRTH_DETAILS_UPDATE)({ patientId, birthDetailsId }),
      payload,
      "application/json",
      loadState(CHILDCARE_USER_TOKEN)
    );

    return res.body;
  }
}

export default new BirthDetailService();
