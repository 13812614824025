import { useState } from "react";
import fields, {
    validationRules,
} from "../../../../../data/admissions/diagnosis/fields";
import initialFields from "../../../../../data/admissions/diagnosis/initialValues";
import { validateForm } from "../../../../../validation/formValidation";
import PatientForm from "../../../Patient/Form/Index";

const Diagnosis = ({ admission, handleDiagnosisUpdate }) => {
  const [formData, setFormData] = useState(
    {   ...initialFields,   
        treatmentReceived: admission.treatmentReceived,
        diagnosis: admission.diagnosis,
    });
  const [formErrors, setFormErrors] = useState({});
  const [diagnosisFields, setDiagnosisFields] = useState(fields);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm(formData, diagnosisFields, validationRules);

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      handleDiagnosisUpdate(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5 bg-white">
      <PatientForm
        formTitle="Diagnosis"
        formFields={diagnosisFields}
        formErrors={formErrors}
        formData={formData}
        handleInputChange={handleInputChange}
      />
    </form>
  );
};

export default Diagnosis;
