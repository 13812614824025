import { useEffect, useState } from "react";
import fields, {
  validationRules,
} from "../../../../../data/admissions/headToToe/fields";
import initialFields from "../../../../../data/admissions/headToToe/initialValues";
import HeadToToeService from "../../../../../services/examinations/HeadToToeService";
import { handleHeadToToeFieldChange } from "../../../../../utils/handleConditionalFields";
import { validateForm } from "../../../../../validation/formValidation";
import PatientForm from "../../../Patient/Form/Index";

const HeadToToeExamination = ({ admissionId, handleCreate, handleCancel }) => {
  const [formData, setFormData] = useState(initialFields);
  const [formErrors, setFormErrors] = useState({});
  const [admissionFields, setAdmissionFields] = useState(fields);

  useEffect(() => {
    fetchHeadToToe();
  }, []);

  const fetchHeadToToe = async () => {
    try {
      const res = await HeadToToeService.getDetail(admissionId);
      if (res.ent.toLowerCase() === "abnormal") {
        setAdmissionFields((prevFields) =>
          prevFields.map((field) =>
            field.label === "entAbnormal"
              ? { ...field, hide: false }
              : field
          )
        );
      }
      setFormData((prevFormData) => ({ ...prevFormData, ...res }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleHeadToToeFieldChange(name, value, setAdmissionFields);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm(formData, admissionFields, validationRules);

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      handleCreate(formData);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="pt-5 pl-3 pr-3 pb-5 bg-white">
      <PatientForm
        formTitle="Head to Toe"
        formFields={admissionFields}
        formErrors={formErrors}
        formData={formData}
        handleInputChange={handleInputChange}
        cancelButton={true}
        handleCancelButtonClick={handleCancel}
      />
    </form>
  );
};

export default HeadToToeExamination;
