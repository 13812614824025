import React, { useEffect, useState } from "react";
import FamilyHistoryService from "../../../../../services/FamilyHistoryService";
import EmptyState from "../../../Admission/Sections/EmptyState";

const FamilyHistoryView = ({ patientId, handleEditClick }) => {
  const [familyHistory, setFamilyHistory] = useState({});

  useEffect(() => {
    fetchFamilyHistory();
  }, []);

  const fetchFamilyHistory = async () => {
    try {
      const res = await FamilyHistoryService.getDetail(patientId);
      setFamilyHistory(res);
    } catch (error) {
      console.error(error);
    }
  };

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  if (!familyHistory.id) {
    return (
      <EmptyState
        handleEditClick={handleEditClick}
        entityName="Family History"
      />
    );
  }

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField("Consanguinity", familyHistory.consanguinity)}
              {renderField(
                "Any significant medical history",
                familyHistory.medicalHistory
              )}

              {familyHistory.medicalHistory === "Yes" &&
                renderField("History value", familyHistory.historyValue)}
              {renderField(
                "Similar illness in family member",
                familyHistory.similarIllnessInFamilyMember
              )}
              {familyHistory.similarIllnessInFamilyMember === "Yes" &&
                renderField("What illness?", familyHistory.whatIllness)}
              {renderField("Type of Family", familyHistory.typeOfFamily)}
              {renderField(
                "History of communicable disease contact",
                familyHistory.historyOfCommunicableDiseaseContact
              )}
              {renderField("Father Occupation", familyHistory.fatherOccupation)}
              {renderField("Mother Occupation", familyHistory.motherOccupation)}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Birth History
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyHistoryView;
