import { useEffect, useState } from 'react';
import SystemicService from '../../../../../services/examinations/SystemicService';
import EmptyState from '../EmptyState';

const SystemicView = ({ admissionId, handleEditClick }) => {
  const [systemic, setSystemic] = useState({});

  useEffect(() => {
    fetchSystemic();
  }, []);

  const fetchSystemic = async () => {
    try {
      const res = await SystemicService.getDetail(admissionId);
      setSystemic(res);
    } catch (error) {
      console.error(error);
    }
  };

  if (!systemic.id) {
    return (
      <EmptyState handleEditClick={handleEditClick} entityName="Systemic" />
    );
  }

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  const renderTitle = (label) => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 pl-3 pt-5">
        <label className="mb-2.5 block text-xl bg-white p-3">{label}</label>
      </div>
    );
  };

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField(
                'Cardiovascular System',
                systemic.cardiovascularSystem,
              )}
              {systemic.cardiovascularSystem === 'Abnormal' &&
                renderField('Abnormal', systemic.cardiovascularSystemAbnormal)}
            </div>
            {/* {renderTitle('Respiratory System')} */}
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Respiratory System', systemic.respiratoryDistress)}
              {systemic.respiratoryDistress === 'Abnormal' &&
                renderField(
                  'Respiratory System - Abnormal',
                  systemic.respiratoryDistressAbnormal,
                )}
            </div>
            {/* {renderTitle('Gastrointestinal System')} */}
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Gastrointestinal System', systemic.ascites)}
              {systemic.ascites === 'Abnormal' &&
                renderField(
                  'Gastrointestinal System - Abnormal',
                  systemic.ascitesAbnormal,
                )}
            </div>
            {/* {renderTitle('Musculoskeletal system')} */}
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Musculoskeletal system', systemic.arthritis)}
              {systemic.arthritis === 'Abnormal' &&
                renderField(
                  'Musculoskeletal system - Abnormal',
                  systemic.arthritisAbnormal,
                )}
            </div>
            {/* {renderTitle('Central nervous system')} */}
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Central nervous system', systemic.dysarthria)}
              {systemic.dysarthria === 'Abnormal' &&
                renderField(
                  'Central nervous system - Abnormal',
                  systemic.dysarthriaAbnormal,
                )}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Systemic
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemicView;
