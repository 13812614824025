import { ArrowLeftIcon, EyeIcon, PencilIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import {
  renderChiefComplaintsValue,
  renderComplaintsValue,
  renderValue,
} from "../../Detail/RenderFields";

const AdmissionDetail = ({ patientId, admission }) => {
  return (
    <div className="flex flex-col md:flex-row gap-9">
      <div
        className={`rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow pl-5`}
      >
        <div className="pt-3 pr-3 flex justify-end">
          <Link
            className="hover:text-meta-3"
            to={`/u/patients/${patientId}/admissions/${admission.id}/vitals`}
          >
            <button
              type="button"
              className="text-white hover:text-meta-3 transition mr-3"
            >
              <EyeIcon className="h-4 w-4" />
            </button>
          </Link>
          <Link to={`/u/patients/${patientId}/admissions/${admission.id}/edit`}>
            <button
              type="button"
              className="text-warning hover:text-warning transition mr-3"
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          </Link>
          <Link to={`/u/admissions`}>
            <button
              type="button"
              className="text-danger hover:text-meta-1 transition mr-3"
            >
              <ArrowLeftIcon className="h-6 w-6" />
            </button>
          </Link>
        </div>
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-5 gap-9 p-3">
            {admission?.patient?.patientName && (
              <div className="flex flex-col flex-grow-0">
                <label className="mb-2.5 block text-black dark:text-white">
                  Patient Name
                </label>
                <Link
                  to={`/u/patients/${admission?.patient?.id}/birth_history`}
                >
                  <span className="text-meta-3">
                    {admission?.patient?.patientName}
                  </span>
                </Link>
              </div>
            )}
            {renderChiefComplaintsValue(
              "Chief Complaints",
              admission.chiefComplaints
            )}
            {renderValue("Past Medical History", admission.pastMedicalHistory)}
            {renderComplaintsValue("Other Complaints", admission.complaints)}
            {renderValue("Present Illness", admission.presentIllness)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionDetail;
