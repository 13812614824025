import { useState } from "react";

const useServerError = () => {
  const [serverError, setServerError] = useState({ message: "" });

  const updateServerError = (error, cb) => {
    setServerError({
      message: error?.body?.message || "Please try again later!",
    });

    if (typeof cb === "function") {
      cb(
        error?.body?.message || "Something went wrong! Please try again later."
      );
    }
  };

  const clearServerError = () => {
    setServerError({ message: "" });
  };

  return { serverError, updateServerError, clearServerError };
};

export default useServerError;
