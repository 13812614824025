const HEMATOLOGY = { name: 'hematology', label: 'Hematology' };
const BIOCHEMISTRY = { name: 'biochemistry', label: 'Biochemistry' };
const PATHOLOGY = { name: 'pathology', label: 'Pathology' };
const MICROBIOLOGY = { name: 'microbiology', label: 'Microbiology' };
const ENDOSCOPY = { name: 'endoscopy', label: 'Endoscopy' };
const IMAGING = { name: 'imaging', label: 'Imaging' };
const PHYSIOMETRY = { name: 'physiometry', label: 'Physiometry' };

const investigationNames = [
  HEMATOLOGY.name,
  BIOCHEMISTRY.name,
  PATHOLOGY.name,
  MICROBIOLOGY.name,
  ENDOSCOPY.name,
  IMAGING.name,
  PHYSIOMETRY.name,
];

const options = [
  {
    id: 1,
    name: HEMATOLOGY.name,
    label: HEMATOLOGY.label,
    hide: true,
  },
  {
    id: 2,
    name: BIOCHEMISTRY.name,
    label: BIOCHEMISTRY.label,
    hide: true,
  },
  {
    id: 3,
    name: PATHOLOGY.name,
    label: PATHOLOGY.label,
    hide: true,
  },
  {
    id: 4,
    name: MICROBIOLOGY.name,
    label: MICROBIOLOGY.label,
    hide: true,
  },
  {
    id: 5,
    name: ENDOSCOPY.name,
    label: ENDOSCOPY.label,
    hide: true,
  },
  {
    id: 6,
    name: IMAGING.name,
    label: IMAGING.label,
    hide: true,
  },
  {
    id: 7,
    name: PHYSIOMETRY.name,
    label: PHYSIOMETRY.label,
    hide: true,
  },
];

const initialSelectedFields = {
  hematology: [],
  biochemistry: [],
  pathology: [],
  microbiology: [],
  endoscopy: [],
  imaging: [],
  physiometry: [],
};

const investigationFields = {
  hematology: [
    {
      investigationType: HEMATOLOGY.name,
      label: 'Hb',
      name: 'hb',
      unit: 'gm/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'TLC',
      name: 'tlc',
      unit: '/ul',
      extraText: 'N L',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Platelet count',
      name: 'plateletCount',
      unit: '/ul',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'PCV',
      name: 'pcv',
      unit: '%',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'MCV',
      name: 'mcv',
      unit: 'fl',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'MCH',
      name: 'mch',
      unit: 'pg',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'MCHC',
      name: 'mchc',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'RDW',
      name: 'rdw',
      unit: '%',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Retics count',
      name: 'reticsCount',
      unit: '%',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'AEC',
      name: 'aec',
      unit: '/ul',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'MP antigen',
      name: 'mpAntigen',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'BT',
      name: 'bt',
      unit: 'min',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'CT',
      name: 'ct',
      unit: 'min',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'PT',
      name: 'pt',
      unit: 'sec',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'PT Control',
      name: 'ptControl',
      unit: 'sec',
      extraText: '',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'INR',
      name: 'inr',
      unit: '',
      extraText: '',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'aPTT',
      name: 'aptt',
      unit: 'sec',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'aPTTControl',
      name: 'apttControl',
      unit: 'sec',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Fibrinogen',
      name: 'fibrinogen',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'FDP d-dimer',
      name: 'fdpDimer',
      unit: 'ng/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Factor VIII activity',
      name: 'factorVIIIActivity',
      unit: '% of normal',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Factor IX activity',
      name: 'factorIXActivity',
      unit: '% of normal',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Hb electrophoresis',
      name: 'hbElectrophoresis',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Sickle cell',
      name: 'sickleCell',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'G6PD screen',
      name: 'g6pdScreen',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'osmotic fragility',
      name: 'osmoticFragility',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Coombs test',
      name: 'coombsTest',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Peripheral blood smear comments',
      name: 'peripheralBloodSmearComments',
      hide: true,
      value: null,
    },
    {
      investigationType: HEMATOLOGY.name,
      label: 'Hematology Others',
      name: 'hematologyOthers',
      hide: true,
      value: null,
    },
  ],
  biochemistry: [
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'RBS',
      name: 'rbs',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,

      label: 'Fasting sugar',
      name: 'fastingSugar',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,

      label: '2hr Post glucose',
      name: 'postGlucose',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,

      label: 'Serum urea',
      name: 'serumUrea',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,

      label: 'Serum Creatinine',
      name: 'serumCreatinine',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,

      label: 'Serum uric acid',
      name: 'serumUricAcid',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,

      label: 'Na-',
      name: 'na',
      unit: 'meq/l',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'K',
      name: 'k',
      unit: 'meq/l',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Chloride',
      name: 'chloride',
      unit: 'meq/l',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Serum osmolarity',
      name: 'serumOsmolarity',
      unit: 'mosm/kg of water',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Mg',
      name: 'mg',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Serum Copper',
      name: 'serumCopper',
      unit: 'ug/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Total calcium',
      name: 'totalCalcium',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Inorganic Phosphorus',
      name: 'inorganicPhosphorus',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Total cholesterol',
      name: 'totalCholesterol',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'HDL',
      name: 'hdl',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'LDL',
      name: 'ldl',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Triacylglycerol',
      name: 'triacylglycerol',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Total bilirubin',
      name: 'totalBilirubin',
      unit: 'gm/l',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Direct bilirubin',
      name: 'directBilirubin',
      unit: 'gm/l',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Total protein',
      name: 'totalProtein',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Albumin',
      name: 'albumin',
      unit: 'mg/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'AG ratio',
      name: 'agRatio',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'SGOT',
      name: 'sgot',
      unit: 'U/l',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'SGPT',
      name: 'sgpt',
      unit: 'U/L',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'ALP',
      name: 'alp',
      unit: 'U/L',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'YGT',
      name: 'ygt',
      unit: 'U/L',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'LDH',
      name: 'ldh',
      unit: 'U/L',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'CPK( Total)',
      name: 'cpkTotal',
      unit: 'U/L',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'CPK MB',
      name: 'cpkMb',
      unit: 'U/L',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Serum Amylase',
      name: 'serumAmylase',
      unit: 'U/L',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Lipase',
      name: 'lipase',
      unit: 'U/L',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'ADA ( serum/CSF/Fluid)',
      name: 'ada',
      unit: '%',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Serum ceruloplasmin',
      name: 'serumCeruloplasmin',
      unit: '%',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'HbA1C',
      name: 'hba1c',
      unit: '%',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: '24hrs urine protein',
      name: 'urineProtein',
      unit: 'gm/day',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: '24hrs urine creatinine',
      name: 'urineCreatinine',
      unit: 'umol/day',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: '24hrs urine uric acid',
      name: 'urineUricAcid',
      unit: 'umol/day',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: '24hr urine calcium',
      name: 'urineCalcium',
      unit: 'mmol/day',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: '24hrs urine phosphorus',
      name: 'urinePhosphorus',
      unit: 'mg/day',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: '24hrs urine Na',
      name: 'urineNa',
      unit: 'meq/day',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: '24hrs urine K',
      name: 'urineK',
      unit: 'meq/day',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: '24hr urine VMA',
      name: 'urineVMA',
      unit: 'mg/day',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: '24hr urine osmolality',
      name: 'urineOsmolality',
      unit: 'mosm/kg of water',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'fT3',
      name: 'ft3',
      unit: 'pmol/l',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'fT4',
      name: 'ft4',
      unit: 'pmol/l',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'TSH',
      name: 'tsh',
      unit: 'uiU/ml Cortisol (8.00 am) ug/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Cortisol (8.00 am)',
      name: 'cortisol',
      unit: 'ug/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Serum Iron',
      name: 'serumIron',
      unit: 'Ug/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'TIBC',
      name: 'tibc',
      unit: 'ug/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Ferritin',
      name: 'ferritin',
      unit: 'ng/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'LH',
      name: 'lh',
      unit: 'uiU/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'FSH',
      name: 'fsh',
      unit: 'uiU/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Prolactin',
      name: 'prolactin',
      unit: 'ng/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Oestrogen',
      name: 'oestrogen',
      unit: 'ng/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Progesterone',
      name: 'progesterone',
      unit: 'ng/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Testosterone',
      name: 'testosterone',
      unit: 'ng/dl',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'B hCG',
      name: 'bhcg',
      unit: 'uiU/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'APF',
      name: 'apf',
      unit: 'ng/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'IPTH',
      name: 'ipth',
      unit: 'pg/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'VIT D',
      name: 'vitD',
      unit: 'ng/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Vit B12',
      name: 'vitB12',
      unit: 'pg/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Folic acid',
      name: 'folicAcid',
      unit: 'ng/ml',
      hide: true,
      value: null,
    },
    {
      investigationType: BIOCHEMISTRY.name,
      label: 'Biochemistry Others',
      name: 'biochemistryOthers',
      unit: '',
      hide: true,
      value: null,
    },
  ],
  pathology: [
    {
      investigationType: PATHOLOGY.name,
      label: 'Fluid Specimen',
      name: 'fluidSpecimen',
      unit: '',
      hide: true,
      value: null,
    },
    {
      investigationType: PATHOLOGY.name,
      label: 'Tissue Specimen',
      name: 'tissueSpecimen',
      unit: '',
      hide: true,
      value: null,
    },
    {
      investigationType: PATHOLOGY.name,
      label: 'Pathology Others',
      name: 'others',
      hide: true,
      value: null,
    },
  ],
  microbiology: [
    {
      investigationType: MICROBIOLOGY.name,
      label: 'AFB staining',
      name: 'afbStaining',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'RA factor',
      name: 'raFactor',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'ASO titre',
      name: 'asoTitre',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'CRP test',
      name: 'crpTest',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HIV Ab spot',
      name: 'hivAbSpot',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HIV ELISA',
      name: 'hivElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HBs Ag spot',
      name: 'hbsAgSpot',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HBs AG ELISA',
      name: 'hbsAgElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HCV Ab spot',
      name: 'hcvAbSpot',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HCV Ab ELISA',
      name: 'hcvAbElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'RPR',
      name: 'rpr',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'TPHA Widal test',
      name: 'tphaWidalTest',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Brucella Ab',
      name: 'brucellaAb',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Leptospira IgM/IgG Ab',
      name: 'leptospiraAb',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Dengue IgM/IgG',
      name: 'dengueIg',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Toxoplasma Ab test',
      name: 'toxoplasmaAb',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HAV IgM',
      name: 'havIgM',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HEV IgM',
      name: 'hevIgM',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HBe Ag ELISA',
      name: 'hbeAgElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HBe AB ELISA',
      name: 'hbeAbElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HBs Ab',
      name: 'hbsAb',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'HBc IgM ELISA',
      name: 'hbcIgMElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Anti CCP ELISA',
      name: 'antiCcpElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'ANA ELISA',
      name: 'anaElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'ds DNA Ab ELISA',
      name: 'dsDnaAbElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Cardiolipin Ab ELISA',
      name: 'cardiolipinAbElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Cysticercosis Ab ELISA',
      name: 'cysticercosisAbElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Echinococcus Ab ELISA',
      name: 'echinococcusAbElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'H. Pylori Ab',
      name: 'hPyloriAb',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'TORCH IgM ELISA',
      name: 'torchIgMElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'TORCH IgG ELISA',
      name: 'torchIgGElisa',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Cryptococcal Ag test',
      name: 'cryptococcalAgTest',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Hs-CRP Blood C/S',
      name: 'hsCrpBloodCs',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Urine C/S',
      name: 'urineCs',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Pus C/S',
      name: 'pusCs',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Sputum C/S',
      name: 'sputumCs',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Stool C/S',
      name: 'stoolCs',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Gram stain',
      name: 'gramStain',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'India Ink',
      name: 'indiaInk',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'KOH',
      name: 'koh',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Swab C/S',
      name: 'swabCs',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Body fluid C/S',
      name: 'bodyFluidCs',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Gene xpert',
      name: 'geneXpert',
      hide: true,
      value: null,
    },
    {
      investigationType: MICROBIOLOGY.name,
      label: 'Microbiology Others',
      name: 'microbiologyOthers',
      hide: true,
      value: null,
    },
  ],
  endoscopy: [
    {
      investigationType: ENDOSCOPY.name,
      label: 'UGI  endoscopy',
      name: 'ufIendoscopy',
      hide: true,
      value: null,
    },
    {
      investigationType: ENDOSCOPY.name,
      label: 'Bronchoscopy',
      name: 'bronchoscopy',
      hide: true,
      value: null,
    },
    {
      investigationType: ENDOSCOPY.name,
      label: 'Sigmoidoscopy',
      name: 'sigmoidoscopy',
      hide: true,
      value: null,
    },
    {
      investigationType: ENDOSCOPY.name,
      label: 'Colonoscopy',
      name: 'colonoscopy',
      hide: true,
      value: null,
    },
    {
      investigationType: ENDOSCOPY.name,
      label: 'Endoscopy Others',
      name: 'endoscopyOthers',
      hide: true,
      value: null,
      specialType: true,
    },
  ],
  imaging: [
    {
      investigationType: IMAGING.name,
      label: 'USG',
      name: 'usg',
      hide: true,
      value: null,
    },
    {
      investigationType: IMAGING.name,
      label: 'Chest X-ray',
      name: 'chestXray',
      hide: true,
      value: null,
    },
    {
      investigationType: IMAGING.name,
      label: 'MRI',
      name: 'mri',
      hide: true,
      value: null,
    },
    {
      investigationType: IMAGING.name,
      label: 'CT scan',
      name: 'ctScan',
      hide: true,
      value: null,
    },
    {
      investigationType: IMAGING.name,
      label: 'MCUG',
      name: 'mcug',
      hide: true,
      value: null,
    },
    {
      investigationType: IMAGING.name,
      label: 'X-ray KUB',
      name: 'xRayCub',
      hide: true,
      value: null,
    },
    {
      investigationType: IMAGING.name,
      label: 'X-ray SKULL',
      name: 'xRaySkull',
      hide: true,
      value: null,
    },
    {
      investigationType: IMAGING.name,
      label: 'X-ray Spine',
      name: 'xRaySpine',
      hide: true,
      value: null,
    },
    {
      investigationType: IMAGING.name,
      label: 'X-ray Pelvis',
      name: 'xRayPelvis',
      hide: true,
      value: null,
    },
    {
      investigationType: IMAGING.name,
      label: 'Imaging Others',
      name: 'imagingOthers',
      hide: true,
      value: null,
    },
  ],
  physiometry: [
    {
      investigationType: PHYSIOMETRY.name,
      label: 'EEG',
      name: 'eeg',
      hide: true,
      value: null,
    },
    {
      investigationType: PHYSIOMETRY.name,
      label: 'ECG',
      name: 'ecg',
      hide: true,
      value: null,
    },
    {
      investigationType: PHYSIOMETRY.name,
      label: 'ECHO',
      name: 'echo',
      hide: true,
      value: null,
    },
    {
      investigationType: PHYSIOMETRY.name,
      label: 'Spirometry',
      name: 'spirometry',
      hide: true,
      value: null,
    },
    {
      investigationType: PHYSIOMETRY.name,
      label: 'Physiometry Others',
      name: 'physiometryOthers',
      hide: true,
      value: null,
    },
  ],
};

const imagableInvestigations = [];

export {
  imagableInvestigations,
  initialSelectedFields,
  investigationFields,
  investigationNames,
  options,
};

// Add
// Fluid Specimen
// Tissue Specimen
// Remove Others

// Fluid Specimen
// Specimen
// TC
// DC
// protein
// sugar
// RBC
// C/S
// Others
