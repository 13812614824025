import React from "react";
import SystemicService from "../../../../../services/examinations/SystemicService";
import Systemic from "./Index";
import SystemicView from "./View";

const SystemicComponent = ({
  admissionId,
  editSystemic,
  setEditSystemic,
  handleCreate,
}) => {
  return (
    <>
      {editSystemic ? (
        <Systemic
          admissionId={admissionId}
          handleCancel={() => setEditSystemic(false)}
          handleCreate={(formData) =>
            handleCreate(
              formData,
              SystemicService,
              "Systemic",
              setEditSystemic
            )
          }
        />
      ) : (
        <SystemicView
          admissionId={admissionId}
          handleEditClick={(e) => {
            e.preventDefault();
            setEditSystemic(true);
          }}
        />
      )}
    </>
  );
};

export default SystemicComponent;
