const fields = [
  {
    label: 'pregnancyEvent',
    name: 'Pregnancy Event',
    type: 'select',
    options: ['Yes', 'No'],
  },
  {
    label: 'pregnancyEventYes',
    name: 'Enter Pregnancy Event',
    type: 'text',
    hide: false,
  },
  {
    label: 'placeOfDelivery',
    name: 'Place of Delivery',
    type: 'select',
    options: ['Health Facility', 'Home', 'Other'],
  },
  {
    label: 'placeOfDeliveryOther',
    name: 'Mention Place',
    type: 'text',
    hide: true,
  },
  {
    label: 'typeOfDelivery',
    name: 'Type of Delivery',
    type: 'select',
    options: ['Spontaneous Vaginal', 'Caesarean', 'Assisted vaginal', 'Other'],
  },
  {
    label: 'typeOfDeliveryOther',
    name: 'Other Detail',
    type: 'text',
    hide: true,
  },
  {
    label: 'assistedVaginal',
    name: 'Assisted Vaginal',
    type: 'select',
    options: ['Assisted breech', 'Vacuum', 'Forceps'],
    hide: true,
  },
  {
    label: 'periodOfGestation',
    name: 'Period of Gestation',
    type: 'select',
    options: [
      'Full term',
      'Preterm (<28 weeks)',
      'Preterm (28-32 weeks)',
      'Preterm (33-36 weeks)',
      'Post term',
    ],
  },
];

export default fields;
