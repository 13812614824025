import Http from "axios";

const cache = [];
const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

class Api {
  async get(resource, params = {}, cacheEnable = true, token = null) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };

    try {
      let res = {};
      let queryString = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&");
      let url = `${BASE_URL}${resource}${queryString ? `?${queryString}` : ""}`;

      if (cacheEnable && cache[url]) {
        res = cache[url];
      } else {
        res = await Http.get(url, config);
        cache[url] = res;
      }

      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async post(
    resource,
    params = {},
    contentType = "application/json",
    token = null
  ) {
    const config = {
      headers: {
        "Content-Type": contentType,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };

    try {
      let response = await Http.post(`${BASE_URL}${resource}`, params, config);
      return this.successResponse(response);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async patch(
    resource,
    params = {},
    contentType = "application/json",
    token = null
  ) {
    const config = {
      headers: {
        "Content-Type": contentType,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };

    try {
      let response = await Http.patch(`${BASE_URL}${resource}`, params, config);
      return this.successResponse(response);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async delete(resource, contentType = "application/json", token = null) {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": contentType,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };

    try {
      let response = await fetch(`${BASE_URL}${resource}`, config);
      return this.successResponse(response);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  successResponse(response) {
    return this.response(response);
  }

  errorResponse(error) {
    if (error?.code === "ERR_NETWORK") {
      const networkResponse = {
        data: { message: "Network error found!" },
        status: 503,
        headers: [],
      };

      throw this.response(networkResponse);
    }

    throw this.response(error.response);
  }

  response({ data, status, headers }) {
    return {
      body: data,
      status,
      headers,
    };
  }

  async getPdf(resource, params = {}, cacheEnable = true, token = null) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      responseType: "arraybuffer",
    };

    try {
      let res = {};
      let queryString = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&");
      let url = `${BASE_URL}${resource}${queryString ? `?${queryString}` : ""}`;

      if (cacheEnable && cache[url]) {
        res = cache[url];
      } else {
        res = await Http.get(url, config);
        cache[url] = res;
      }
      return res;
    } catch (error) {
      return this.errorResponse(error);
    }
  }
}

export default new Api();
