import { useEffect, useState } from "react";
import HeadToToeService from "../../../../../services/examinations/HeadToToeService";
import EmptyState from "../EmptyState";

const HeadToToeView = ({ admissionId, handleEditClick }) => {
  const [headToToe, setHeadToToe] = useState({});

  useEffect(() => {
    fetchHeadToToe();
  }, []);

  const fetchHeadToToe = async () => {
    try {
      const res = await HeadToToeService.getDetail(admissionId);
      setHeadToToe(res);
    } catch (error) {
      console.error(error);
    }
  };

  if (!headToToe.id) {
    return (
      <EmptyState
        handleEditClick={handleEditClick}
        entityName="Head to Toe Examination"
      />
    );
  }

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField("Anterior Fontanelle", headToToe.anteriorFontanelle)}
              {renderField("HENT", headToToe.ent)}
              {renderField(
                "Obvious congenital Malformations",
                headToToe.obviousCongenitalMalformations
              )}
              {renderField("Dysmorphic Features", headToToe.dysmorphicFeatures)}
              {renderField("Rash", headToToe.rash)}
              {renderField("Pallor", headToToe.pallor)}
              {renderField("Icterus", headToToe.icterus)}
              {renderField("Clubbing", headToToe.clubbing)}
              {renderField("Lymphadenopathy", headToToe.lymphadenopathy)}
              {renderField("Pedal Edema", headToToe.pedalEdema)}
              {renderField("Bony tenderness", headToToe.bonyTenderness)}
              {renderField(
                "Other Specific Findings",
                headToToe.otherSpecificFindings
              )}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Head to toe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadToToeView;
