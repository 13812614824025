const initialFields = {
  bcg: 'Yes',
  pentavacOPV: 'Yes',
  pentavacDoses: 'Dose 1',
  opv: 'Yes',
  opvDoses: 'Dose 1',
  rota: 'Yes',
  rotaDoses: 'Dose 1',
  pcv10: 'Yes',
  pcv10Doses: 'Dose 1',
  fipv: 'Yes',
  fipvDoses: 'Dose 1',
  mr: 'Yes',
  mrDoses: 'Dose 1',
  je: 'Yes',
  tcv: 'Yes',
  others: [],
};

export default initialFields;
