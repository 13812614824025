import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

const Layout = ({ title, initialOpen, children }) => {
  const [isFormOpen, setIsFormOpen] = useState(initialOpen);

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex items-center justify-between">
            <h4 className="text-xl font-semibold text-black dark:text-white">
              {title}
            </h4>
            <button
              className="p-2 hover:text-meta-3"
              onClick={() => setIsFormOpen((prevState) => !prevState)}
            >
              {isFormOpen ? (
                <ChevronDownIcon className="w-5 h-5" />
              ) : (
                <ChevronUpIcon className="w-5 h-5" />
              )}
            </button>
          </div>
          <div className="border-b border-stroke dark:border-strokedark"></div>
          {isFormOpen && children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
