import React, { useEffect, useState } from 'react';
import SocialHistoryService from '../../../../../services/SocialHistoryService';
import EmptyState from '../../../Admission/Sections/EmptyState';

const SocialHistoryView = ({ patientId, handleEditClick }) => {
  const [socialHistory, setSocialHistory] = useState({});

  useEffect(() => {
    fetchSocialHistory();
  }, []);

  const fetchSocialHistory = async () => {
    try {
      const res = await SocialHistoryService.getDetail(patientId);
      setSocialHistory(res);
    } catch (error) {
      console.error(error);
    }
  };

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  if (!socialHistory.id) {
    return (
      <EmptyState
        handleEditClick={handleEditClick}
        entityName="Social History"
      />
    );
  }

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Father Education', socialHistory.parentalEducation)}
              {renderField('Mother Education', socialHistory.motherEducation)}
              {renderField('Housing condition', socialHistory.housingCondition)}
              {renderField('Pets at Home', socialHistory.petsAtHome)}
              {renderField('Water Source', socialHistory.waterSource)}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Social History
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialHistoryView;
