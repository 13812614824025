import { getCurrentDate } from '../../../utils';

let initialValues = {
  outcome: 'Cured',
  outcomeTiming: 'Within 48 Hours',
  referredToOtherHospitals: 'No',
  courseDuringHospitalStay: '',
  adviceOnDischarge: '',
  followUp: '',
  dateOfDischarge: getCurrentDate(),
};

export default initialValues;
